// extracted by mini-css-extract-plugin
export var selectDropdown = "AddUser-module--selectDropdown--2m6OG";
export var addUserWrapper = "AddUser-module--addUserWrapper--P5dKK";
export var availableLicense = "AddUser-module--availableLicense--3ds9-";
export var groupName = "AddUser-module--groupName--ULV2E";
export var addEmail = "AddUser-module--addEmail--z2CfD";
export var labelWrapper = "AddUser-module--labelWrapper--NbkE0";
export var usersLabel = "AddUser-module--usersLabel--1wpHM";
export var selectedUserLength = "AddUser-module--selectedUserLength--1124H";
export var courseLabel = "AddUser-module--courseLabel--2KhWW";
export var courseTitleWrapper = "AddUser-module--courseTitleWrapper--1RKdp";
export var text = "AddUser-module--text--uxWbT";
export var flexWrapper = "AddUser-module--flexWrapper--3-yrl";
export var textSection = "AddUser-module--textSection--1o0-9";
export var downloadFormat = "AddUser-module--downloadFormat--2DEoo";
export var maxUploadNote = "AddUser-module--maxUploadNote--2N_DT";
export var uploadButton = "AddUser-module--uploadButton--1O8Zy";
export var assignCourseCta = "AddUser-module--assignCourseCta--1ZnpH";
export var loader = "AddUser-module--loader--2gEO7";
export var importContactsContainer = "AddUser-module--importContactsContainer--2bTCg";
export var importContacts = "AddUser-module--importContacts--1vUdw";
export var importContactsSubText = "AddUser-module--importContactsSubText--3O95-";
export var assignCourseWrapper = "AddUser-module--assignCourseWrapper--1EQp1";
export var assignCtaWrapper = "AddUser-module--assignCtaWrapper--3_n5l";
export var btnBg = "AddUser-module--btnBg--2JlBH";
export var uploadCsv = "AddUser-module--uploadCsv--1SRkk";