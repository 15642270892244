import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { API } from 'aws-amplify';
import * as styles from '../../../../styles/admin/Account.module.scss';
import Layout from '../../../../components/Admin/Layout';
import CourseDetails from '../../../../components/Admin/Browse/CourseDetails';
import Authenticate from '../../../../components/Identity/Authenticate';
import AssignCoursePopUp from '../../../../components/Admin/Browse/Popups/AssignCoursePopUp';
import { fetchUserBySub, listUsersWithRoleInGroup, fetchRegistrationByCourseId } from '../../../../components/DataStore/Services';
import { createCourseRegistration } from '../../../../graphql/mutations';
import { CourseRegistrationStatus } from '../../../../models';
import { toaster } from '../../../../services/utils';
import { AdminProvider } from '../../../../context/Admin/AdminContext';

/**
 *
 *
 * @return {*}
 */
function CourseDetailsPage(props) {
  const {
    identityProviderClient, location,
  } = props;
  const [showAssignCourse, setShowAssignCourse] = useState(false);

  /**
   * create courseReg of users
   *
   * @param {*} sub
   * @return {*}
   */
  const createCourseRegistrationHandler = async (sub, id = null) => {
    const courseID = location?.state?.course?.id;
    const userRes = await fetchUserBySub(sub, id);

    // check if the course is already requested by the user.
    let isRegistered = [];
    const courseReg = await fetchRegistrationByCourseId(courseID);
    if (courseReg && courseReg?.length !== 0) {
      isRegistered = courseReg?.filter((reg) => (reg?.userSub === sub));
    }
    if (isRegistered?.length === 0 && userRes) {
      // create a course reg
      return API.graphql({
        query: createCourseRegistration,
        variables: {
          input: {
            courseID,
            userID: userRes.id,
            status: CourseRegistrationStatus.APPROVED,
          },
        },
      });
    }
    return null;
  };

  /**
   * create courseReg of users in group
   *
   * @param {*} groupId
   */
  const createCourseRegistrationForGroupHandler = async (groupId) => {
    const users = await listUsersWithRoleInGroup(groupId);
    if (users?.length !== 0) {
      // find or create courseRegistration
      const promises = users.map(
        (userInGroup) => (createCourseRegistrationHandler(userInGroup?.user?.sub,
          userInGroup?.user?.id)),
      );
      Promise.all(promises).then((res) => {
        if (res.includes(null)) {
          toaster('Course is already assigned');
        } else {
          toaster('Course assigned', 'success', 1);
        }
      })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  /**
   * Assign courses to group/user
   *
   * @param {*} values
   */
  const assignCourses = async (values) => {
    setShowAssignCourse(false);
    if (values?.selectedUsers && values?.selectedUsers?.length !== 0) {
      // find or create courseRegistration
      const promises = values?.selectedUsers.map(
        (userSub) => (createCourseRegistrationHandler(userSub)),
      );
      await Promise.all(promises).then((res) => {
        if (res.includes(null)) {
          toaster('Course is already assigned', 'success', 1);
        } else {
          toaster('Course assigned', 'success', 1);
        }
      })
        .catch((err) => {
          console.log(err);
        });
    }
    if (values?.selectedGroups && values?.selectedGroups?.length !== 0) {
      for (let i = 0; i < values?.selectedGroups?.length; i += 1) {
        createCourseRegistrationForGroupHandler(values?.selectedGroups[i]);
      }
    }
  };
  return (
    <AdminProvider>
      <div className={styles.accountPageWrapper}>
        <Layout
          title=""
          Component={CourseDetails}
          tabs={{}}
          activeTab=""
          identityProviderClient={identityProviderClient}
          location={location}
          courseDetailsCtaText="Assign"
          browseRoute="/admin/browse/"
          courseDetailsCtaHandler={() => setShowAssignCourse(true)}
        />
        <AssignCoursePopUp
          isModalVisible={showAssignCourse}
          selectedCourse={location?.state?.course}
          onCancel={() => setShowAssignCourse(false)}
          onOk={assignCourses}
        />
      </div>
    </AdminProvider>
  );
}
CourseDetailsPage.defaultProps = {
  location: {},
};
CourseDetailsPage.propTypes = {
  identityProviderClient: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any),
};
export default Authenticate(CourseDetailsPage);
