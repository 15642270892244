/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type adminUpdateUserInput = {
  id: string,
  enabled?: boolean | null,
  roles?: Array< Role | null > | null,
  status?: Status | null,
  _version?: number | null,
};

export enum Role {
  OWNER = "OWNER",
  ADMIN = "ADMIN",
  MANAGER = "MANAGER",
}


export enum Status {
  UNCONFIRMED = "UNCONFIRMED",
  CONFIRMED = "CONFIRMED",
  ARCHIVED = "ARCHIVED",
  COMPROMISED = "COMPROMISED",
  UNKNOWN = "UNKNOWN",
  RESET_REQUIRED = "RESET_REQUIRED",
  FORCE_CHANGE_PASSWORD = "FORCE_CHANGE_PASSWORD",
}


export type User = {
  __typename: "User",
  id: string,
  sub?: string | null,
  name?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  email: string,
  enabled?: boolean | null,
  status: Status,
  roles: Array< Role >,
  lastActive?: string | null,
  deletedAt?: string | null,
  enterpriseID?: string | null,
  phoneNumber?: string | null,
  designation?: string | null,
  optin?: boolean | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  createdAt: string,
  updatedAt: string,
  groupMemberships?: ModelGroupMemberConnection | null,
};

export type ModelGroupMemberConnection = {
  __typename: "ModelGroupMemberConnection",
  items?:  Array<GroupMember | null > | null,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type GroupMember = {
  __typename: "GroupMember",
  id: string,
  memberID: string,
  groupID: string,
  isManager?: boolean | null,
  isLearner?: boolean | null,
  userSub?: string | null,
  deletedAt?: string | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  createdAt: string,
  updatedAt: string,
  member: User,
  group: Group,
};

export type Group = {
  __typename: "Group",
  id: string,
  name: string,
  maxMembers?: number | null,
  deletedAt?: string | null,
  enterpriseID?: string | null,
  membersCount?: number | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  createdAt: string,
  updatedAt: string,
  members?: ModelGroupMemberConnection | null,
};

export type adminCreateGroupInput = {
  id?: string | null,
  name: string,
  maxMembers?: number | null,
  deletedAt?: string | null,
  enterpriseID?: string | null,
  membersCount?: number | null,
  _version?: number | null,
};

export type adminUpdateGroupInput = {
  id: string,
  name?: string | null,
  maxMembers?: number | null,
  deletedAt?: string | null,
  _version?: number | null,
};

export type adminCreateGroupMemberInput = {
  id?: string | null,
  memberID: string,
  groupID: string,
  isManager?: boolean | null,
  isLearner?: boolean | null,
  userSub?: string | null,
  deletedAt?: string | null,
  _version?: number | null,
};

export type adminUpdateGroupMemberInput = {
  id: string,
  isManager?: boolean | null,
  isLearner?: boolean | null,
  _version?: number | null,
  deletedAt?: string | null,
};

export type adminCreateCourseRegistrationInput = {
  id?: string | null,
  courseID: string,
  userID: string,
  userSub: string,
  status: CourseRegistrationStatus,
  deadline?: string | null,
  deletedAt?: string | null,
  enterpriseID: string,
  assignedByUserID: string,
  _version?: number | null,
};

export enum CourseRegistrationStatus {
  ASSIGNED = "ASSIGNED",
  REQUESTED = "REQUESTED",
  APPROVED = "APPROVED",
  COMPLETED = "COMPLETED",
}


export type CourseRegistration = {
  __typename: "CourseRegistration",
  id: string,
  courseID: string,
  userID: string,
  userSub?: string | null,
  status: CourseRegistrationStatus,
  deadline?: string | null,
  deletedAt?: string | null,
  enterpriseID?: string | null,
  assignedByUserID?: string | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  createdAt: string,
  updatedAt: string,
};

export type RequestReportSubmission = {
  enterpriseID: string,
  userID: string,
  reportID: string,
};

export type ResetReportSubmission = {
  enterpriseID: string,
  userID: string,
  reportID: string,
};

export type CreateCourseInput = {
  id?: string | null,
  source: string,
  sourceID: string,
  locale: string,
  courseName: string,
  courseDescription?: string | null,
  starterPack?: ResourceCollectionInput | null,
  active?: boolean | null,
  modules: Array< CourseModuleInput | null >,
  tags: Array< string >,
  categories: Array< string >,
  courseTileImage?: LinkInput | null,
  courseHeroBannerImage?: LinkInput | null,
  deletedAt?: string | null,
  type?: CourseType | null,
  enterpriseID?: string | null,
  learningStandard?: CourseLearningStandard | null,
  accessType?: CourseAccessType | null,
  _version?: number | null,
  status?: CourseStatus | null,
  uploadType?: CourseUploadType | null,
};

export type ResourceCollectionInput = {
  title: string,
  slug: string,
  items: Array< ResourceInput >,
};

export type ResourceInput = {
  resourceType: ResourceType,
  documentResource?: DocumentResourceInput | null,
  videoResource?: VideoResourceInput | null,
};

export enum ResourceType {
  DOCUMENT = "DOCUMENT",
  VIDEO = "VIDEO",
}


export type DocumentResourceInput = {
  source: DocumentSource,
  link?: LinkInput | null,
};

export enum DocumentSource {
  LINK = "LINK",
}


export type LinkInput = {
  title: string,
  href: string,
};

export type VideoResourceInput = {
  source: VideoSource,
  name: string,
  uri?: string | null,
};

export enum VideoSource {
  VIMEO = "VIMEO",
  SCORM_VIDEO = "SCORM_VIDEO",
}


export type CourseModuleInput = {
  id: string,
  source: string,
  sourceID: string,
  locale: string,
  moduleName: string,
  moduleDescription?: string | null,
  tasks: Array< TaskInput >,
  createdAt: string,
  updatedAt: string,
};

export type TaskInput = {
  id: string,
  taskType: TaskType,
  videoTask?: VideoTaskInput | null,
  assignmentTask?: AssignmentTaskInput | null,
  finalAssignmentTask?: FinalAssignmentTaskInput | null,
};

export enum TaskType {
  VIDEO = "VIDEO",
  ASSIGNMENT = "ASSIGNMENT",
  FINAL_ASSIGNMENT = "FINAL_ASSIGNMENT",
}


export type VideoTaskInput = {
  lessonName: string,
  lessonDescription?: string | null,
  lessonVideo: VideoResourceInput,
  lessonResources: Array< ResourceInput >,
};

export type AssignmentTaskInput = {
  source: AssignmentSource,
  title: string,
  formId?: string | null,
};

export enum AssignmentSource {
  TYPEFORM = "TYPEFORM",
}


export type FinalAssignmentTaskInput = {
  source: AssignmentSource,
  title: string,
  formId?: string | null,
};

export enum CourseType {
  DIPLOMA_COURSE = "DIPLOMA_COURSE",
  CERTIFIED_COURSE = "CERTIFIED_COURSE",
  ACCELERATOR_COURSE = "ACCELERATOR_COURSE",
}


export enum CourseLearningStandard {
  CMI5 = "CMI5",
}


export enum CourseAccessType {
  PUBLIC = "PUBLIC",
  PRIVATE = "PRIVATE",
}


export enum CourseStatus {
  PROCESSING = "PROCESSING",
  AVAILABLE = "AVAILABLE",
  UPLOAD_FAILED = "UPLOAD_FAILED",
}


export enum CourseUploadType {
  SINGLE_VIDEO_DIRECT = "SINGLE_VIDEO_DIRECT",
}


export type ModelCourseConditionInput = {
  source?: ModelStringInput | null,
  sourceID?: ModelStringInput | null,
  locale?: ModelStringInput | null,
  courseName?: ModelStringInput | null,
  courseDescription?: ModelStringInput | null,
  active?: ModelBooleanInput | null,
  tags?: ModelStringInput | null,
  categories?: ModelStringInput | null,
  deletedAt?: ModelStringInput | null,
  type?: ModelCourseTypeInput | null,
  enterpriseID?: ModelIDInput | null,
  learningStandard?: ModelCourseLearningStandardInput | null,
  accessType?: ModelCourseAccessTypeInput | null,
  status?: ModelCourseStatusInput | null,
  uploadType?: ModelCourseUploadTypeInput | null,
  and?: Array< ModelCourseConditionInput | null > | null,
  or?: Array< ModelCourseConditionInput | null > | null,
  not?: ModelCourseConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelCourseTypeInput = {
  eq?: CourseType | null,
  ne?: CourseType | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelCourseLearningStandardInput = {
  eq?: CourseLearningStandard | null,
  ne?: CourseLearningStandard | null,
};

export type ModelCourseAccessTypeInput = {
  eq?: CourseAccessType | null,
  ne?: CourseAccessType | null,
};

export type ModelCourseStatusInput = {
  eq?: CourseStatus | null,
  ne?: CourseStatus | null,
};

export type ModelCourseUploadTypeInput = {
  eq?: CourseUploadType | null,
  ne?: CourseUploadType | null,
};

export type Course = {
  __typename: "Course",
  id: string,
  source: string,
  sourceID: string,
  locale: string,
  courseName: string,
  courseDescription?: string | null,
  starterPack?: ResourceCollection | null,
  active?: boolean | null,
  modules:  Array<CourseModule >,
  tags: Array< string | null >,
  categories: Array< string | null >,
  courseTileImage?: Link | null,
  courseHeroBannerImage?: Link | null,
  deletedAt?: string | null,
  type?: CourseType | null,
  enterpriseID?: string | null,
  learningStandard?: CourseLearningStandard | null,
  accessType?: CourseAccessType | null,
  status?: CourseStatus | null,
  uploadType?: CourseUploadType | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  createdAt: string,
  updatedAt: string,
};

export type ResourceCollection = {
  __typename: "ResourceCollection",
  title: string,
  slug: string,
  items:  Array<Resource >,
};

export type Resource = {
  __typename: "Resource",
  resourceType: ResourceType,
  documentResource?: DocumentResource | null,
  videoResource?: VideoResource | null,
};

export type DocumentResource = {
  __typename: "DocumentResource",
  source: DocumentSource,
  link?: Link | null,
};

export type Link = {
  __typename: "Link",
  title: string,
  href: string,
};

export type VideoResource = {
  __typename: "VideoResource",
  source: VideoSource,
  name: string,
  uri?: string | null,
};

export type CourseModule = {
  __typename: "CourseModule",
  id: string,
  source: string,
  sourceID: string,
  locale: string,
  moduleName: string,
  moduleDescription?: string | null,
  tasks:  Array<Task >,
  createdAt: string,
  updatedAt: string,
};

export type Task = {
  __typename: "Task",
  id: string,
  taskType: TaskType,
  videoTask?: VideoTask | null,
  assignmentTask?: AssignmentTask | null,
  finalAssignmentTask?: FinalAssignmentTask | null,
};

export type VideoTask = {
  __typename: "VideoTask",
  lessonName: string,
  lessonDescription?: string | null,
  lessonVideo: VideoResource,
  lessonResources:  Array<Resource | null >,
};

export type AssignmentTask = {
  __typename: "AssignmentTask",
  source: AssignmentSource,
  title: string,
  formId?: string | null,
};

export type FinalAssignmentTask = {
  __typename: "FinalAssignmentTask",
  source: AssignmentSource,
  title: string,
  formId?: string | null,
};

export type AdminUpdateCourseInput = {
  id: string,
  source?: string | null,
  sourceID?: string | null,
  locale?: string | null,
  courseName?: string | null,
  courseDescription?: string | null,
  starterPack?: ResourceCollectionInput | null,
  active?: boolean | null,
  modules?: Array< CourseModuleInput > | null,
  tags?: Array< string | null > | null,
  categories?: Array< string | null > | null,
  courseTileImage?: LinkInput | null,
  courseHeroBannerImage?: LinkInput | null,
  deletedAt?: string | null,
  type?: CourseType | null,
  enterpriseID?: string | null,
  learningStandard?: CourseLearningStandard | null,
  accessType?: CourseAccessType | null,
  status?: CourseStatus | null,
  uploadType?: CourseUploadType | null,
  _version?: number | null,
};

export type CreateUserInput = {
  id?: string | null,
  sub?: string | null,
  name?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  email: string,
  enabled?: boolean | null,
  status: Status,
  roles: Array< Role >,
  lastActive?: string | null,
  deletedAt?: string | null,
  enterpriseID?: string | null,
  phoneNumber?: string | null,
  designation?: string | null,
  optin?: boolean | null,
  _version?: number | null,
};

export type ModelUserConditionInput = {
  name?: ModelStringInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  enabled?: ModelBooleanInput | null,
  status?: ModelStatusInput | null,
  roles?: ModelRoleListInput | null,
  lastActive?: ModelStringInput | null,
  deletedAt?: ModelStringInput | null,
  enterpriseID?: ModelIDInput | null,
  phoneNumber?: ModelStringInput | null,
  designation?: ModelStringInput | null,
  optin?: ModelBooleanInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
};

export type ModelStatusInput = {
  eq?: Status | null,
  ne?: Status | null,
};

export type ModelRoleListInput = {
  eq?: Array< Role | null > | null,
  ne?: Array< Role | null > | null,
  contains?: Role | null,
  notContains?: Role | null,
};

export type DeleteUserInput = {
  id: string,
  _version?: number | null,
};

export type UpdateUserInput = {
  id: string,
  sub?: string | null,
  name?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
  enabled?: boolean | null,
  status?: Status | null,
  roles?: Array< Role > | null,
  lastActive?: string | null,
  deletedAt?: string | null,
  enterpriseID?: string | null,
  phoneNumber?: string | null,
  designation?: string | null,
  optin?: boolean | null,
  _version?: number | null,
};

export type CreateGroupInput = {
  id?: string | null,
  name: string,
  maxMembers?: number | null,
  deletedAt?: string | null,
  enterpriseID?: string | null,
  membersCount?: number | null,
  _version?: number | null,
};

export type ModelGroupConditionInput = {
  name?: ModelStringInput | null,
  maxMembers?: ModelIntInput | null,
  deletedAt?: ModelStringInput | null,
  enterpriseID?: ModelIDInput | null,
  membersCount?: ModelIntInput | null,
  and?: Array< ModelGroupConditionInput | null > | null,
  or?: Array< ModelGroupConditionInput | null > | null,
  not?: ModelGroupConditionInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type DeleteGroupInput = {
  id: string,
  _version?: number | null,
};

export type UpdateGroupInput = {
  id: string,
  name?: string | null,
  maxMembers?: number | null,
  deletedAt?: string | null,
  enterpriseID?: string | null,
  membersCount?: number | null,
  _version?: number | null,
};

export type CreateGroupMemberInput = {
  id?: string | null,
  memberID: string,
  groupID: string,
  isManager?: boolean | null,
  isLearner?: boolean | null,
  userSub?: string | null,
  deletedAt?: string | null,
  _version?: number | null,
};

export type ModelGroupMemberConditionInput = {
  memberID?: ModelIDInput | null,
  groupID?: ModelIDInput | null,
  isManager?: ModelBooleanInput | null,
  isLearner?: ModelBooleanInput | null,
  deletedAt?: ModelStringInput | null,
  and?: Array< ModelGroupMemberConditionInput | null > | null,
  or?: Array< ModelGroupMemberConditionInput | null > | null,
  not?: ModelGroupMemberConditionInput | null,
};

export type DeleteGroupMemberInput = {
  id: string,
  _version?: number | null,
};

export type UpdateGroupMemberInput = {
  id: string,
  memberID?: string | null,
  groupID?: string | null,
  isManager?: boolean | null,
  isLearner?: boolean | null,
  userSub?: string | null,
  deletedAt?: string | null,
  _version?: number | null,
};

export type CreateEnterpriseProfileInput = {
  id?: string | null,
  name: string,
  licenseCount: number,
  membership?: EnterpriseMembershipInput | null,
  subscription?: EnterpriseSubscriptionInput | null,
  courseContentVersion?: number | null,
  deletedAt?: string | null,
  numberOfEmployees?: string | null,
  location?: string | null,
  hiddenCourses?: Array< string > | null,
  scormCloudAppID?: string | null,
  _version?: number | null,
};

export type EnterpriseMembershipInput = {
  name: string,
  status: EnterpriseMembershipStatus,
  createdAt: string,
  updatedAt: string,
};

export enum EnterpriseMembershipStatus {
  IN_TRIAL = "IN_TRIAL",
  ACTIVE = "ACTIVE",
  NON_RENEWING = "NON_RENEWING",
  PAUSED = "PAUSED",
  CANCELLED = "CANCELLED",
  DISABLED = "DISABLED",
}


export type EnterpriseSubscriptionInput = {
  id: string,
  provider: SubscriptionProvider,
  providerSubID: string,
  providerCustomerID: string,
  status: EnterpriseSubscriptionStatus,
  entitlements: Array< SubscriptionEntitlementInput | null >,
  currencyCode?: string | null,
  trialStart?: string | null,
  trialEnd?: string | null,
  currentTermStart?: string | null,
  currentTermEnd?: string | null,
  nextBillingAt?: string | null,
  cancelledAt?: string | null,
  items: Array< SubscriptionItemInput | null >,
  billingPeriod?: number | null,
  billingPeriodUnit?: BillingPeriodUnit | null,
  resourceVersion?: string | null,
  createdAt: string,
  updatedAt: string,
};

export enum SubscriptionProvider {
  CHARGEBEE = "CHARGEBEE",
}


export enum EnterpriseSubscriptionStatus {
  ACTIVE = "ACTIVE",
  NON_RENEWING = "NON_RENEWING",
  PAUSED = "PAUSED",
  CANCELLED = "CANCELLED",
}


export type SubscriptionEntitlementInput = {
  featureSlug: FeatureSlug,
  featureType: FeatureType,
  featureUnit?: string | null,
  value?: string | null,
};

export enum FeatureSlug {
  USER_LICENCES = "USER_LICENCES",
  MONTHLY_LESSONS = "MONTHLY_LESSONS",
}


export enum FeatureType {
  SWITCH = "SWITCH",
  CUSTOM = "CUSTOM",
  QUANTITY = "QUANTITY",
  RANGE = "RANGE",
}


export type SubscriptionItemInput = {
  itemPriceId: string,
  itemType: SubscriptionItemType,
  quantity?: number | null,
  unitPrice?: string | null,
  amount?: string | null,
  freeQuantity?: number | null,
  name?: string | null,
};

export enum SubscriptionItemType {
  PLAN = "PLAN",
}


export enum BillingPeriodUnit {
  DAY = "DAY",
  WEEK = "WEEK",
  MONTH = "MONTH",
  YEAR = "YEAR",
}


export type ModelEnterpriseProfileConditionInput = {
  name?: ModelStringInput | null,
  licenseCount?: ModelIntInput | null,
  courseContentVersion?: ModelIntInput | null,
  deletedAt?: ModelStringInput | null,
  numberOfEmployees?: ModelStringInput | null,
  location?: ModelStringInput | null,
  hiddenCourses?: ModelIDInput | null,
  scormCloudAppID?: ModelStringInput | null,
  and?: Array< ModelEnterpriseProfileConditionInput | null > | null,
  or?: Array< ModelEnterpriseProfileConditionInput | null > | null,
  not?: ModelEnterpriseProfileConditionInput | null,
};

export type EnterpriseProfile = {
  __typename: "EnterpriseProfile",
  id: string,
  name: string,
  licenseCount: number,
  membership?: EnterpriseMembership | null,
  subscription?: EnterpriseSubscription | null,
  courseContentVersion?: number | null,
  deletedAt?: string | null,
  numberOfEmployees?: string | null,
  location?: string | null,
  hiddenCourses?: Array< string > | null,
  scormCloudAppID?: string | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  createdAt: string,
  updatedAt: string,
};

export type EnterpriseMembership = {
  __typename: "EnterpriseMembership",
  name: string,
  status: EnterpriseMembershipStatus,
  createdAt: string,
  updatedAt: string,
};

export type EnterpriseSubscription = {
  __typename: "EnterpriseSubscription",
  id: string,
  provider: SubscriptionProvider,
  providerSubID: string,
  providerCustomerID: string,
  status: EnterpriseSubscriptionStatus,
  entitlements:  Array<SubscriptionEntitlement | null >,
  currencyCode?: string | null,
  trialStart?: string | null,
  trialEnd?: string | null,
  currentTermStart?: string | null,
  currentTermEnd?: string | null,
  nextBillingAt?: string | null,
  cancelledAt?: string | null,
  items:  Array<SubscriptionItem | null >,
  billingPeriod?: number | null,
  billingPeriodUnit?: BillingPeriodUnit | null,
  resourceVersion?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type SubscriptionEntitlement = {
  __typename: "SubscriptionEntitlement",
  featureSlug: FeatureSlug,
  featureType: FeatureType,
  featureUnit?: string | null,
  value?: string | null,
};

export type SubscriptionItem = {
  __typename: "SubscriptionItem",
  itemPriceId: string,
  itemType: SubscriptionItemType,
  quantity?: number | null,
  unitPrice?: string | null,
  amount?: string | null,
  freeQuantity?: number | null,
  name?: string | null,
};

export type UpdateEnterpriseProfileInput = {
  id: string,
  name?: string | null,
  licenseCount?: number | null,
  membership?: EnterpriseMembershipInput | null,
  subscription?: EnterpriseSubscriptionInput | null,
  courseContentVersion?: number | null,
  deletedAt?: string | null,
  numberOfEmployees?: string | null,
  location?: string | null,
  hiddenCourses?: Array< string > | null,
  scormCloudAppID?: string | null,
  _version?: number | null,
};

export type DeleteEnterpriseProfileInput = {
  id: string,
  _version?: number | null,
};

export type UpdateCourseInput = {
  id: string,
  source?: string | null,
  sourceID?: string | null,
  locale?: string | null,
  courseName?: string | null,
  courseDescription?: string | null,
  starterPack?: ResourceCollectionInput | null,
  active?: boolean | null,
  modules?: Array< CourseModuleInput > | null,
  tags?: Array< string | null > | null,
  categories?: Array< string | null > | null,
  courseTileImage?: LinkInput | null,
  courseHeroBannerImage?: LinkInput | null,
  deletedAt?: string | null,
  type?: CourseType | null,
  enterpriseID?: string | null,
  learningStandard?: CourseLearningStandard | null,
  accessType?: CourseAccessType | null,
  status?: CourseStatus | null,
  uploadType?: CourseUploadType | null,
  _version?: number | null,
};

export type DeleteCourseInput = {
  id: string,
  _version?: number | null,
};

export type CreateSourceInput = {
  id?: string | null,
  name: string,
  _version?: number | null,
};

export type ModelSourceConditionInput = {
  name?: ModelStringInput | null,
  and?: Array< ModelSourceConditionInput | null > | null,
  or?: Array< ModelSourceConditionInput | null > | null,
  not?: ModelSourceConditionInput | null,
};

export type Source = {
  __typename: "Source",
  id: string,
  name: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  createdAt: string,
  updatedAt: string,
};

export type UpdateSourceInput = {
  id: string,
  name?: string | null,
  _version?: number | null,
};

export type DeleteSourceInput = {
  id: string,
  _version?: number | null,
};

export type CreateReportInput = {
  id?: string | null,
  sourceReferenceID: string,
  sourceID: string,
  locale: string,
  name: string,
  heading?: string | null,
  subHeading?: string | null,
  startCTA?: string | null,
  explainer?: string | null,
  assessment: AssignmentTaskInput,
  skills: Array< SkillInput >,
  deletedAt?: string | null,
  _version?: number | null,
};

export type SkillInput = {
  id: string,
  label: string,
  skillReport?: LinkInput | null,
  iconLink?: LinkInput | null,
  selectedIconLink?: LinkInput | null,
  subSkills: Array< SubSkillInput >,
};

export type SubSkillInput = {
  id: string,
  label: string,
  description?: string | null,
};

export type ModelReportConditionInput = {
  sourceReferenceID?: ModelStringInput | null,
  sourceID?: ModelIDInput | null,
  locale?: ModelStringInput | null,
  name?: ModelStringInput | null,
  heading?: ModelStringInput | null,
  subHeading?: ModelStringInput | null,
  startCTA?: ModelStringInput | null,
  explainer?: ModelStringInput | null,
  deletedAt?: ModelStringInput | null,
  and?: Array< ModelReportConditionInput | null > | null,
  or?: Array< ModelReportConditionInput | null > | null,
  not?: ModelReportConditionInput | null,
};

export type Report = {
  __typename: "Report",
  id: string,
  sourceReferenceID: string,
  sourceID: string,
  locale: string,
  name: string,
  heading?: string | null,
  subHeading?: string | null,
  startCTA?: string | null,
  explainer?: string | null,
  assessment: AssignmentTask,
  skills:  Array<Skill >,
  deletedAt?: string | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  createdAt: string,
  updatedAt: string,
  source?: Source | null,
};

export type Skill = {
  __typename: "Skill",
  id: string,
  label: string,
  skillReport?: Link | null,
  iconLink?: Link | null,
  selectedIconLink?: Link | null,
  subSkills:  Array<SubSkill >,
};

export type SubSkill = {
  __typename: "SubSkill",
  id: string,
  label: string,
  description?: string | null,
};

export type UpdateReportInput = {
  id: string,
  sourceReferenceID?: string | null,
  sourceID?: string | null,
  locale?: string | null,
  name?: string | null,
  heading?: string | null,
  subHeading?: string | null,
  startCTA?: string | null,
  explainer?: string | null,
  assessment?: AssignmentTaskInput | null,
  skills?: Array< SkillInput > | null,
  deletedAt?: string | null,
  _version?: number | null,
};

export type DeleteReportInput = {
  id: string,
  _version?: number | null,
};

export type CreateReportResultInput = {
  id?: string | null,
  reportID: string,
  userID: string,
  userSub: string,
  formID: string,
  responseID: string,
  skillScores: Array< SkillScoreInput >,
  deletedAt?: string | null,
  enterpriseID: string,
  archived?: boolean | null,
  _version?: number | null,
};

export type SkillScoreInput = {
  skillID: string,
  score: number,
  maxScore: number,
  subSkillScores: Array< SubSkillScoreInput >,
};

export type SubSkillScoreInput = {
  subSkillID: string,
  score: number,
  maxScore: number,
  scoreKey: string,
};

export type ModelReportResultConditionInput = {
  reportID?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  formID?: ModelIDInput | null,
  responseID?: ModelStringInput | null,
  deletedAt?: ModelStringInput | null,
  enterpriseID?: ModelIDInput | null,
  archived?: ModelBooleanInput | null,
  and?: Array< ModelReportResultConditionInput | null > | null,
  or?: Array< ModelReportResultConditionInput | null > | null,
  not?: ModelReportResultConditionInput | null,
};

export type ReportResult = {
  __typename: "ReportResult",
  id: string,
  reportID: string,
  userID: string,
  userSub: string,
  formID: string,
  responseID: string,
  skillScores:  Array<SkillScore >,
  deletedAt?: string | null,
  enterpriseID: string,
  archived?: boolean | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  createdAt: string,
  updatedAt: string,
  user?: User | null,
  report?: Report | null,
};

export type SkillScore = {
  __typename: "SkillScore",
  skillID: string,
  score: number,
  maxScore: number,
  subSkillScores:  Array<SubSkillScore >,
};

export type SubSkillScore = {
  __typename: "SubSkillScore",
  subSkillID: string,
  score: number,
  maxScore: number,
  scoreKey: string,
};

export type UpdateReportResultInput = {
  id: string,
  reportID?: string | null,
  userID?: string | null,
  userSub?: string | null,
  formID?: string | null,
  responseID?: string | null,
  skillScores?: Array< SkillScoreInput > | null,
  deletedAt?: string | null,
  enterpriseID?: string | null,
  archived?: boolean | null,
  _version?: number | null,
};

export type DeleteReportResultInput = {
  id: string,
  _version?: number | null,
};

export type CreateReportSubmissionInput = {
  id?: string | null,
  reportID: string,
  userID: string,
  userSub: string,
  status: ReportSubmissionStatus,
  deletedAt?: string | null,
  enterpriseID: string,
  _version?: number | null,
};

export enum ReportSubmissionStatus {
  STARTED = "STARTED",
  IN_REVIEW = "IN_REVIEW",
  COMPLETED = "COMPLETED",
}


export type ModelReportSubmissionConditionInput = {
  reportID?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  status?: ModelReportSubmissionStatusInput | null,
  deletedAt?: ModelStringInput | null,
  enterpriseID?: ModelIDInput | null,
  and?: Array< ModelReportSubmissionConditionInput | null > | null,
  or?: Array< ModelReportSubmissionConditionInput | null > | null,
  not?: ModelReportSubmissionConditionInput | null,
};

export type ModelReportSubmissionStatusInput = {
  eq?: ReportSubmissionStatus | null,
  ne?: ReportSubmissionStatus | null,
};

export type ReportSubmission = {
  __typename: "ReportSubmission",
  id: string,
  reportID: string,
  userID: string,
  userSub: string,
  status: ReportSubmissionStatus,
  deletedAt?: string | null,
  enterpriseID: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  createdAt: string,
  updatedAt: string,
};

export type UpdateReportSubmissionInput = {
  id: string,
  reportID?: string | null,
  userID?: string | null,
  userSub?: string | null,
  status?: ReportSubmissionStatus | null,
  deletedAt?: string | null,
  enterpriseID?: string | null,
  _version?: number | null,
};

export type DeleteReportSubmissionInput = {
  id: string,
  _version?: number | null,
};

export type CreateTypeformResponseInput = {
  id?: string | null,
  formID: string,
  userID?: string | null,
  assessmentType?: string | null,
  payload: string,
  deletedAt?: string | null,
  _version?: number | null,
};

export type ModelTypeformResponseConditionInput = {
  formID?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  assessmentType?: ModelStringInput | null,
  payload?: ModelStringInput | null,
  deletedAt?: ModelStringInput | null,
  and?: Array< ModelTypeformResponseConditionInput | null > | null,
  or?: Array< ModelTypeformResponseConditionInput | null > | null,
  not?: ModelTypeformResponseConditionInput | null,
};

export type TypeformResponse = {
  __typename: "TypeformResponse",
  id: string,
  formID: string,
  userID?: string | null,
  assessmentType?: string | null,
  payload: string,
  deletedAt?: string | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  createdAt: string,
  updatedAt: string,
};

export type UpdateTypeformResponseInput = {
  id: string,
  formID?: string | null,
  userID?: string | null,
  assessmentType?: string | null,
  payload?: string | null,
  deletedAt?: string | null,
  _version?: number | null,
};

export type DeleteTypeformResponseInput = {
  id: string,
  _version?: number | null,
};

export type CreateCourseRegistrationInput = {
  id?: string | null,
  courseID: string,
  userID: string,
  userSub?: string | null,
  status: CourseRegistrationStatus,
  deadline?: string | null,
  deletedAt?: string | null,
  enterpriseID?: string | null,
  assignedByUserID?: string | null,
  _version?: number | null,
};

export type ModelCourseRegistrationConditionInput = {
  courseID?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  status?: ModelCourseRegistrationStatusInput | null,
  deadline?: ModelStringInput | null,
  deletedAt?: ModelStringInput | null,
  enterpriseID?: ModelIDInput | null,
  assignedByUserID?: ModelIDInput | null,
  and?: Array< ModelCourseRegistrationConditionInput | null > | null,
  or?: Array< ModelCourseRegistrationConditionInput | null > | null,
  not?: ModelCourseRegistrationConditionInput | null,
};

export type ModelCourseRegistrationStatusInput = {
  eq?: CourseRegistrationStatus | null,
  ne?: CourseRegistrationStatus | null,
};

export type UpdateCourseRegistrationInput = {
  id: string,
  courseID?: string | null,
  userID?: string | null,
  userSub?: string | null,
  status?: CourseRegistrationStatus | null,
  deadline?: string | null,
  deletedAt?: string | null,
  enterpriseID?: string | null,
  assignedByUserID?: string | null,
  _version?: number | null,
};

export type DeleteCourseRegistrationInput = {
  id: string,
  _version?: number | null,
};

export type CreateCourseRegistrationActivityInput = {
  id?: string | null,
  courseRegistrationID: string,
  userID: string,
  userSub?: string | null,
  taskID: string,
  taskType: TaskType,
  courseID: string,
  status: CourseRegistrationActivityStatus,
  deletedAt?: string | null,
  _version?: number | null,
};

export enum CourseRegistrationActivityStatus {
  STARTED = "STARTED",
  IN_REVIEW = "IN_REVIEW",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
}


export type ModelCourseRegistrationActivityConditionInput = {
  courseRegistrationID?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  taskID?: ModelIDInput | null,
  taskType?: ModelTaskTypeInput | null,
  courseID?: ModelIDInput | null,
  status?: ModelCourseRegistrationActivityStatusInput | null,
  deletedAt?: ModelStringInput | null,
  and?: Array< ModelCourseRegistrationActivityConditionInput | null > | null,
  or?: Array< ModelCourseRegistrationActivityConditionInput | null > | null,
  not?: ModelCourseRegistrationActivityConditionInput | null,
};

export type ModelTaskTypeInput = {
  eq?: TaskType | null,
  ne?: TaskType | null,
};

export type ModelCourseRegistrationActivityStatusInput = {
  eq?: CourseRegistrationActivityStatus | null,
  ne?: CourseRegistrationActivityStatus | null,
};

export type CourseRegistrationActivity = {
  __typename: "CourseRegistrationActivity",
  id: string,
  courseRegistrationID: string,
  userID: string,
  userSub?: string | null,
  taskID: string,
  taskType: TaskType,
  courseID: string,
  status: CourseRegistrationActivityStatus,
  deletedAt?: string | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  createdAt: string,
  updatedAt: string,
  user?: User | null,
  courseRegistration?: CourseRegistration | null,
};

export type UpdateCourseRegistrationActivityInput = {
  id: string,
  courseRegistrationID?: string | null,
  userID?: string | null,
  userSub?: string | null,
  taskID?: string | null,
  taskType?: TaskType | null,
  courseID?: string | null,
  status?: CourseRegistrationActivityStatus | null,
  deletedAt?: string | null,
  _version?: number | null,
};

export type DeleteCourseRegistrationActivityInput = {
  id: string,
  _version?: number | null,
};

export type CreateAssignmentResponseInput = {
  id?: string | null,
  courseRegistrationID: string,
  userID: string,
  userSub: string,
  taskID: string,
  source: AssignmentSource,
  formID: string,
  responseID: string,
  score: number,
  totalScore: number,
  deletedAt?: string | null,
  _version?: number | null,
};

export type ModelAssignmentResponseConditionInput = {
  courseRegistrationID?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  taskID?: ModelIDInput | null,
  source?: ModelAssignmentSourceInput | null,
  formID?: ModelStringInput | null,
  responseID?: ModelStringInput | null,
  score?: ModelIntInput | null,
  totalScore?: ModelIntInput | null,
  deletedAt?: ModelStringInput | null,
  and?: Array< ModelAssignmentResponseConditionInput | null > | null,
  or?: Array< ModelAssignmentResponseConditionInput | null > | null,
  not?: ModelAssignmentResponseConditionInput | null,
};

export type ModelAssignmentSourceInput = {
  eq?: AssignmentSource | null,
  ne?: AssignmentSource | null,
};

export type AssignmentResponse = {
  __typename: "AssignmentResponse",
  id: string,
  courseRegistrationID: string,
  userID: string,
  userSub: string,
  taskID: string,
  source: AssignmentSource,
  formID: string,
  responseID: string,
  score: number,
  totalScore: number,
  deletedAt?: string | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  createdAt: string,
  updatedAt: string,
};

export type UpdateAssignmentResponseInput = {
  id: string,
  courseRegistrationID?: string | null,
  userID?: string | null,
  userSub?: string | null,
  taskID?: string | null,
  source?: AssignmentSource | null,
  formID?: string | null,
  responseID?: string | null,
  score?: number | null,
  totalScore?: number | null,
  deletedAt?: string | null,
  _version?: number | null,
};

export type DeleteAssignmentResponseInput = {
  id: string,
  _version?: number | null,
};

export type CreateNotificationInput = {
  id?: string | null,
  userID: string,
  userSub: string,
  title: string,
  message?: string | null,
  read?: boolean | null,
  deletedAt?: string | null,
  _version?: number | null,
};

export type ModelNotificationConditionInput = {
  userID?: ModelIDInput | null,
  title?: ModelStringInput | null,
  message?: ModelStringInput | null,
  read?: ModelBooleanInput | null,
  deletedAt?: ModelStringInput | null,
  and?: Array< ModelNotificationConditionInput | null > | null,
  or?: Array< ModelNotificationConditionInput | null > | null,
  not?: ModelNotificationConditionInput | null,
};

export type Notification = {
  __typename: "Notification",
  id: string,
  userID: string,
  userSub: string,
  title: string,
  message?: string | null,
  read?: boolean | null,
  deletedAt?: string | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  createdAt: string,
  updatedAt: string,
  user?: User | null,
};

export type UpdateNotificationInput = {
  id: string,
  userID?: string | null,
  userSub?: string | null,
  title?: string | null,
  message?: string | null,
  read?: boolean | null,
  deletedAt?: string | null,
  _version?: number | null,
};

export type DeleteNotificationInput = {
  id: string,
  _version?: number | null,
};

export type CreateInvitationInput = {
  id?: string | null,
  email: string,
  status: InvitationStatus,
  inviterID: string,
  inviterEnterpriseID: string,
  inviteeID?: string | null,
  roles?: Array< Role | null > | null,
  groupID?: string | null,
  _version?: number | null,
};

export enum InvitationStatus {
  REQUESTED = "REQUESTED",
  ACCEPTED = "ACCEPTED",
  REVOKED = "REVOKED",
}


export type ModelInvitationConditionInput = {
  status?: ModelInvitationStatusInput | null,
  inviterID?: ModelIDInput | null,
  inviteeID?: ModelIDInput | null,
  roles?: ModelRoleListInput | null,
  groupID?: ModelIDInput | null,
  and?: Array< ModelInvitationConditionInput | null > | null,
  or?: Array< ModelInvitationConditionInput | null > | null,
  not?: ModelInvitationConditionInput | null,
};

export type ModelInvitationStatusInput = {
  eq?: InvitationStatus | null,
  ne?: InvitationStatus | null,
};

export type Invitation = {
  __typename: "Invitation",
  id: string,
  email: string,
  status: InvitationStatus,
  inviterID: string,
  inviterEnterpriseID: string,
  inviteeID?: string | null,
  roles?: Array< Role | null > | null,
  groupID?: string | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  createdAt: string,
  updatedAt: string,
  inviter?: User | null,
};

export type UpdateInvitationInput = {
  id?: string | null,
  email: string,
  status?: InvitationStatus | null,
  inviterID?: string | null,
  inviterEnterpriseID: string,
  inviteeID?: string | null,
  roles?: Array< Role | null > | null,
  groupID?: string | null,
  _version?: number | null,
};

export type DeleteInvitationInput = {
  email: string,
  inviterEnterpriseID: string,
  _version?: number | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelUserFilterInput = {
  id?: ModelIDInput | null,
  sub?: ModelStringInput | null,
  name?: ModelStringInput | null,
  email?: ModelStringInput | null,
  enabled?: ModelBooleanInput | null,
  status?: ModelStatusInput | null,
  roles?: ModelRoleListInput | null,
  lastActive?: ModelStringInput | null,
  deletedAt?: ModelStringInput | null,
  enterpriseID?: ModelIDInput | null,
  phoneNumber?: ModelStringInput | null,
  designation?: ModelStringInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items?:  Array<User | null > | null,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelGroupFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  maxMembers?: ModelIntInput | null,
  deletedAt?: ModelStringInput | null,
  enterpriseID?: ModelIDInput | null,
  membersCount?: ModelIntInput | null,
  and?: Array< ModelGroupFilterInput | null > | null,
  or?: Array< ModelGroupFilterInput | null > | null,
  not?: ModelGroupFilterInput | null,
};

export type ModelGroupConnection = {
  __typename: "ModelGroupConnection",
  items?:  Array<Group | null > | null,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelInvitationFilterInput = {
  id?: ModelIDInput | null,
  email?: ModelStringInput | null,
  status?: ModelInvitationStatusInput | null,
  inviterID?: ModelIDInput | null,
  inviterEnterpriseID?: ModelIDInput | null,
  inviteeID?: ModelIDInput | null,
  roles?: ModelRoleListInput | null,
  groupID?: ModelIDInput | null,
  and?: Array< ModelInvitationFilterInput | null > | null,
  or?: Array< ModelInvitationFilterInput | null > | null,
  not?: ModelInvitationFilterInput | null,
};

export type ModelInvitationConnection = {
  __typename: "ModelInvitationConnection",
  items?:  Array<Invitation | null > | null,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelEnterpriseProfileFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  licenseCount?: ModelIntInput | null,
  courseContentVersion?: ModelIntInput | null,
  deletedAt?: ModelStringInput | null,
  size?: ModelStringInput | null,
  location?: ModelStringInput | null,
  scormCloudAppID?: ModelStringInput | null,
  and?: Array< ModelEnterpriseProfileFilterInput | null > | null,
  or?: Array< ModelEnterpriseProfileFilterInput | null > | null,
  not?: ModelEnterpriseProfileFilterInput | null,
};

export type ModelEnterpriseProfileConnection = {
  __typename: "ModelEnterpriseProfileConnection",
  items?:  Array<EnterpriseProfile | null > | null,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type RbacMatrixConnection = {
  __typename: "RbacMatrixConnection",
  items?:  Array<RbacMatrix | null > | null,
};

export type RbacMatrix = {
  __typename: "RbacMatrix",
  resource?: RbacResource | null,
  roles?: Array< Role | null > | null,
  userID?: string | null,
};

export type RbacResource = {
  __typename: "RbacResource",
  type?: string | null,
  id?: string | null,
};

export type ModelReportResultFilterInput = {
  id?: ModelIDInput | null,
  reportID?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  userSub?: ModelStringInput | null,
  formID?: ModelIDInput | null,
  responseID?: ModelStringInput | null,
  deletedAt?: ModelStringInput | null,
  enterpriseID?: ModelIDInput | null,
  and?: Array< ModelReportResultFilterInput | null > | null,
  or?: Array< ModelReportResultFilterInput | null > | null,
  not?: ModelReportResultFilterInput | null,
};

export type ModelReportResultConnection = {
  __typename: "ModelReportResultConnection",
  items?:  Array<ReportResult | null > | null,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelCourseRegistrationFilterInput = {
  id?: ModelIDInput | null,
  courseID?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  userSub?: ModelStringInput | null,
  status?: ModelCourseRegistrationStatusInput | null,
  deadline?: ModelStringInput | null,
  deletedAt?: ModelStringInput | null,
  enterpriseID?: ModelIDInput | null,
  and?: Array< ModelCourseRegistrationFilterInput | null > | null,
  or?: Array< ModelCourseRegistrationFilterInput | null > | null,
  not?: ModelCourseRegistrationFilterInput | null,
};

export type ModelCourseRegistrationConnection = {
  __typename: "ModelCourseRegistrationConnection",
  items?:  Array<CourseRegistration | null > | null,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelCourseFilterInput = {
  id?: ModelIDInput | null,
  source?: ModelStringInput | null,
  sourceID?: ModelStringInput | null,
  locale?: ModelStringInput | null,
  courseName?: ModelStringInput | null,
  courseDescription?: ModelStringInput | null,
  active?: ModelBooleanInput | null,
  tags?: ModelStringInput | null,
  categories?: ModelStringInput | null,
  deletedAt?: ModelStringInput | null,
  type?: ModelCourseTypeInput | null,
  enterpriseID?: ModelIDInput | null,
  learningStandard?: ModelCourseLearningStandardInput | null,
  accessType?: ModelCourseAccessTypeInput | null,
  status?: ModelCourseStatusInput | null,
  uploadType?: ModelCourseUploadTypeInput | null,
  and?: Array< ModelCourseFilterInput | null > | null,
  or?: Array< ModelCourseFilterInput | null > | null,
  not?: ModelCourseFilterInput | null,
};

export type ModelCourseConnection = {
  __typename: "ModelCourseConnection",
  items?:  Array<Course | null > | null,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelGroupMemberFilterInput = {
  id?: ModelIDInput | null,
  memberID?: ModelIDInput | null,
  groupID?: ModelIDInput | null,
  isManager?: ModelBooleanInput | null,
  isLearner?: ModelBooleanInput | null,
  userSub?: ModelStringInput | null,
  deletedAt?: ModelStringInput | null,
  and?: Array< ModelGroupMemberFilterInput | null > | null,
  or?: Array< ModelGroupMemberFilterInput | null > | null,
  not?: ModelGroupMemberFilterInput | null,
};

export type ModelSourceFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  and?: Array< ModelSourceFilterInput | null > | null,
  or?: Array< ModelSourceFilterInput | null > | null,
  not?: ModelSourceFilterInput | null,
};

export type ModelSourceConnection = {
  __typename: "ModelSourceConnection",
  items?:  Array<Source | null > | null,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelReportFilterInput = {
  id?: ModelIDInput | null,
  sourceReferenceID?: ModelStringInput | null,
  sourceID?: ModelIDInput | null,
  locale?: ModelStringInput | null,
  name?: ModelStringInput | null,
  heading?: ModelStringInput | null,
  subHeading?: ModelStringInput | null,
  startCTA?: ModelStringInput | null,
  explainer?: ModelStringInput | null,
  deletedAt?: ModelStringInput | null,
  and?: Array< ModelReportFilterInput | null > | null,
  or?: Array< ModelReportFilterInput | null > | null,
  not?: ModelReportFilterInput | null,
};

export type ModelReportConnection = {
  __typename: "ModelReportConnection",
  items?:  Array<Report | null > | null,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelReportSubmissionFilterInput = {
  id?: ModelIDInput | null,
  reportID?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  userSub?: ModelStringInput | null,
  status?: ModelReportSubmissionStatusInput | null,
  deletedAt?: ModelStringInput | null,
  enterpriseID?: ModelIDInput | null,
  and?: Array< ModelReportSubmissionFilterInput | null > | null,
  or?: Array< ModelReportSubmissionFilterInput | null > | null,
  not?: ModelReportSubmissionFilterInput | null,
};

export type ModelReportSubmissionConnection = {
  __typename: "ModelReportSubmissionConnection",
  items?:  Array<ReportSubmission | null > | null,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelTypeformResponseFilterInput = {
  id?: ModelIDInput | null,
  formID?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  assessmentType?: ModelStringInput | null,
  payload?: ModelStringInput | null,
  deletedAt?: ModelStringInput | null,
  and?: Array< ModelTypeformResponseFilterInput | null > | null,
  or?: Array< ModelTypeformResponseFilterInput | null > | null,
  not?: ModelTypeformResponseFilterInput | null,
};

export type ModelTypeformResponseConnection = {
  __typename: "ModelTypeformResponseConnection",
  items?:  Array<TypeformResponse | null > | null,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelCourseRegistrationActivityFilterInput = {
  id?: ModelIDInput | null,
  courseRegistrationID?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  userSub?: ModelStringInput | null,
  taskID?: ModelIDInput | null,
  taskType?: ModelTaskTypeInput | null,
  courseID?: ModelIDInput | null,
  status?: ModelCourseRegistrationActivityStatusInput | null,
  deletedAt?: ModelStringInput | null,
  and?: Array< ModelCourseRegistrationActivityFilterInput | null > | null,
  or?: Array< ModelCourseRegistrationActivityFilterInput | null > | null,
  not?: ModelCourseRegistrationActivityFilterInput | null,
};

export type ModelCourseRegistrationActivityConnection = {
  __typename: "ModelCourseRegistrationActivityConnection",
  items?:  Array<CourseRegistrationActivity | null > | null,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelAssignmentResponseFilterInput = {
  id?: ModelIDInput | null,
  courseRegistrationID?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  userSub?: ModelStringInput | null,
  taskID?: ModelIDInput | null,
  source?: ModelAssignmentSourceInput | null,
  formID?: ModelStringInput | null,
  responseID?: ModelStringInput | null,
  score?: ModelIntInput | null,
  totalScore?: ModelIntInput | null,
  deletedAt?: ModelStringInput | null,
  and?: Array< ModelAssignmentResponseFilterInput | null > | null,
  or?: Array< ModelAssignmentResponseFilterInput | null > | null,
  not?: ModelAssignmentResponseFilterInput | null,
};

export type ModelAssignmentResponseConnection = {
  __typename: "ModelAssignmentResponseConnection",
  items?:  Array<AssignmentResponse | null > | null,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelNotificationFilterInput = {
  id?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  userSub?: ModelStringInput | null,
  title?: ModelStringInput | null,
  message?: ModelStringInput | null,
  read?: ModelBooleanInput | null,
  deletedAt?: ModelStringInput | null,
  and?: Array< ModelNotificationFilterInput | null > | null,
  or?: Array< ModelNotificationFilterInput | null > | null,
  not?: ModelNotificationFilterInput | null,
};

export type ModelNotificationConnection = {
  __typename: "ModelNotificationConnection",
  items?:  Array<Notification | null > | null,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelIDKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type AdminUpdateUserMutationVariables = {
  input: adminUpdateUserInput,
};

export type AdminUpdateUserMutation = {
  adminUpdateUser?:  {
    __typename: "User",
    id: string,
    sub?: string | null,
    name?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    email: string,
    enabled?: boolean | null,
    status: Status,
    roles: Array< Role >,
    lastActive?: string | null,
    deletedAt?: string | null,
    enterpriseID?: string | null,
    phoneNumber?: string | null,
    designation?: string | null,
    optin?: boolean | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    groupMemberships?:  {
      __typename: "ModelGroupMemberConnection",
      items?:  Array< {
        __typename: "GroupMember",
        id: string,
        memberID: string,
        groupID: string,
        isManager?: boolean | null,
        isLearner?: boolean | null,
        userSub?: string | null,
        deletedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type AdminCreateGroupMutationVariables = {
  input: adminCreateGroupInput,
};

export type AdminCreateGroupMutation = {
  adminCreateGroup?:  {
    __typename: "Group",
    id: string,
    name: string,
    maxMembers?: number | null,
    deletedAt?: string | null,
    enterpriseID?: string | null,
    membersCount?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    members?:  {
      __typename: "ModelGroupMemberConnection",
      items?:  Array< {
        __typename: "GroupMember",
        id: string,
        memberID: string,
        groupID: string,
        isManager?: boolean | null,
        isLearner?: boolean | null,
        userSub?: string | null,
        deletedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type AdminUpdateGroupMutationVariables = {
  input: adminUpdateGroupInput,
};

export type AdminUpdateGroupMutation = {
  adminUpdateGroup?:  {
    __typename: "Group",
    id: string,
    name: string,
    maxMembers?: number | null,
    deletedAt?: string | null,
    enterpriseID?: string | null,
    membersCount?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    members?:  {
      __typename: "ModelGroupMemberConnection",
      items?:  Array< {
        __typename: "GroupMember",
        id: string,
        memberID: string,
        groupID: string,
        isManager?: boolean | null,
        isLearner?: boolean | null,
        userSub?: string | null,
        deletedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type AdminCreateGroupMemberMutationVariables = {
  input: adminCreateGroupMemberInput,
};

export type AdminCreateGroupMemberMutation = {
  adminCreateGroupMember?:  {
    __typename: "GroupMember",
    id: string,
    memberID: string,
    groupID: string,
    isManager?: boolean | null,
    isLearner?: boolean | null,
    userSub?: string | null,
    deletedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    member:  {
      __typename: "User",
      id: string,
      sub?: string | null,
      name?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      enabled?: boolean | null,
      status: Status,
      roles: Array< Role >,
      lastActive?: string | null,
      deletedAt?: string | null,
      enterpriseID?: string | null,
      phoneNumber?: string | null,
      designation?: string | null,
      optin?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      groupMemberships?:  {
        __typename: "ModelGroupMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    },
    group:  {
      __typename: "Group",
      id: string,
      name: string,
      maxMembers?: number | null,
      deletedAt?: string | null,
      enterpriseID?: string | null,
      membersCount?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      members?:  {
        __typename: "ModelGroupMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    },
  } | null,
};

export type AdminUpdateGroupMemberMutationVariables = {
  input: adminUpdateGroupMemberInput,
};

export type AdminUpdateGroupMemberMutation = {
  adminUpdateGroupMember?:  {
    __typename: "GroupMember",
    id: string,
    memberID: string,
    groupID: string,
    isManager?: boolean | null,
    isLearner?: boolean | null,
    userSub?: string | null,
    deletedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    member:  {
      __typename: "User",
      id: string,
      sub?: string | null,
      name?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      enabled?: boolean | null,
      status: Status,
      roles: Array< Role >,
      lastActive?: string | null,
      deletedAt?: string | null,
      enterpriseID?: string | null,
      phoneNumber?: string | null,
      designation?: string | null,
      optin?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      groupMemberships?:  {
        __typename: "ModelGroupMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    },
    group:  {
      __typename: "Group",
      id: string,
      name: string,
      maxMembers?: number | null,
      deletedAt?: string | null,
      enterpriseID?: string | null,
      membersCount?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      members?:  {
        __typename: "ModelGroupMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    },
  } | null,
};

export type AdminCreateCourseRegistrationMutationVariables = {
  input: adminCreateCourseRegistrationInput,
};

export type AdminCreateCourseRegistrationMutation = {
  adminCreateCourseRegistration?:  {
    __typename: "CourseRegistration",
    id: string,
    courseID: string,
    userID: string,
    userSub?: string | null,
    status: CourseRegistrationStatus,
    deadline?: string | null,
    deletedAt?: string | null,
    enterpriseID?: string | null,
    assignedByUserID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type AdminRequestReportSubmissionMutationVariables = {
  input?: RequestReportSubmission | null,
};

export type AdminRequestReportSubmissionMutation = {
  adminRequestReportSubmission?: boolean | null,
};

export type AdminResetReportSubmissionMutationVariables = {
  input?: ResetReportSubmission | null,
};

export type AdminResetReportSubmissionMutation = {
  adminResetReportSubmission?: boolean | null,
};

export type AdminCreateCourseMutationVariables = {
  input: CreateCourseInput,
  condition?: ModelCourseConditionInput | null,
};

export type AdminCreateCourseMutation = {
  adminCreateCourse?:  {
    __typename: "Course",
    id: string,
    source: string,
    sourceID: string,
    locale: string,
    courseName: string,
    courseDescription?: string | null,
    starterPack?:  {
      __typename: "ResourceCollection",
      title: string,
      slug: string,
      items:  Array< {
        __typename: "Resource",
        resourceType: ResourceType,
      } >,
    } | null,
    active?: boolean | null,
    modules:  Array< {
      __typename: "CourseModule",
      id: string,
      source: string,
      sourceID: string,
      locale: string,
      moduleName: string,
      moduleDescription?: string | null,
      tasks:  Array< {
        __typename: "Task",
        id: string,
        taskType: TaskType,
      } >,
      createdAt: string,
      updatedAt: string,
    } >,
    tags: Array< string | null >,
    categories: Array< string | null >,
    courseTileImage?:  {
      __typename: "Link",
      title: string,
      href: string,
    } | null,
    courseHeroBannerImage?:  {
      __typename: "Link",
      title: string,
      href: string,
    } | null,
    deletedAt?: string | null,
    type?: CourseType | null,
    enterpriseID?: string | null,
    learningStandard?: CourseLearningStandard | null,
    accessType?: CourseAccessType | null,
    status?: CourseStatus | null,
    uploadType?: CourseUploadType | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type AdminUpdateCourseMutationVariables = {
  input: AdminUpdateCourseInput,
};

export type AdminUpdateCourseMutation = {
  adminUpdateCourse?:  {
    __typename: "Course",
    id: string,
    source: string,
    sourceID: string,
    locale: string,
    courseName: string,
    courseDescription?: string | null,
    starterPack?:  {
      __typename: "ResourceCollection",
      title: string,
      slug: string,
      items:  Array< {
        __typename: "Resource",
        resourceType: ResourceType,
      } >,
    } | null,
    active?: boolean | null,
    modules:  Array< {
      __typename: "CourseModule",
      id: string,
      source: string,
      sourceID: string,
      locale: string,
      moduleName: string,
      moduleDescription?: string | null,
      tasks:  Array< {
        __typename: "Task",
        id: string,
        taskType: TaskType,
      } >,
      createdAt: string,
      updatedAt: string,
    } >,
    tags: Array< string | null >,
    categories: Array< string | null >,
    courseTileImage?:  {
      __typename: "Link",
      title: string,
      href: string,
    } | null,
    courseHeroBannerImage?:  {
      __typename: "Link",
      title: string,
      href: string,
    } | null,
    deletedAt?: string | null,
    type?: CourseType | null,
    enterpriseID?: string | null,
    learningStandard?: CourseLearningStandard | null,
    accessType?: CourseAccessType | null,
    status?: CourseStatus | null,
    uploadType?: CourseUploadType | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateUserMutationVariables = {
  input: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    id: string,
    sub?: string | null,
    name?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    email: string,
    enabled?: boolean | null,
    status: Status,
    roles: Array< Role >,
    lastActive?: string | null,
    deletedAt?: string | null,
    enterpriseID?: string | null,
    phoneNumber?: string | null,
    designation?: string | null,
    optin?: boolean | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    groupMemberships?:  {
      __typename: "ModelGroupMemberConnection",
      items?:  Array< {
        __typename: "GroupMember",
        id: string,
        memberID: string,
        groupID: string,
        isManager?: boolean | null,
        isLearner?: boolean | null,
        userSub?: string | null,
        deletedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "User",
    id: string,
    sub?: string | null,
    name?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    email: string,
    enabled?: boolean | null,
    status: Status,
    roles: Array< Role >,
    lastActive?: string | null,
    deletedAt?: string | null,
    enterpriseID?: string | null,
    phoneNumber?: string | null,
    designation?: string | null,
    optin?: boolean | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    groupMemberships?:  {
      __typename: "ModelGroupMemberConnection",
      items?:  Array< {
        __typename: "GroupMember",
        id: string,
        memberID: string,
        groupID: string,
        isManager?: boolean | null,
        isLearner?: boolean | null,
        userSub?: string | null,
        deletedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    id: string,
    sub?: string | null,
    name?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    email: string,
    enabled?: boolean | null,
    status: Status,
    roles: Array< Role >,
    lastActive?: string | null,
    deletedAt?: string | null,
    enterpriseID?: string | null,
    phoneNumber?: string | null,
    designation?: string | null,
    optin?: boolean | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    groupMemberships?:  {
      __typename: "ModelGroupMemberConnection",
      items?:  Array< {
        __typename: "GroupMember",
        id: string,
        memberID: string,
        groupID: string,
        isManager?: boolean | null,
        isLearner?: boolean | null,
        userSub?: string | null,
        deletedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type CreateGroupMutationVariables = {
  input: CreateGroupInput,
  condition?: ModelGroupConditionInput | null,
};

export type CreateGroupMutation = {
  createGroup?:  {
    __typename: "Group",
    id: string,
    name: string,
    maxMembers?: number | null,
    deletedAt?: string | null,
    enterpriseID?: string | null,
    membersCount?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    members?:  {
      __typename: "ModelGroupMemberConnection",
      items?:  Array< {
        __typename: "GroupMember",
        id: string,
        memberID: string,
        groupID: string,
        isManager?: boolean | null,
        isLearner?: boolean | null,
        userSub?: string | null,
        deletedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type DeleteGroupMutationVariables = {
  input: DeleteGroupInput,
  condition?: ModelGroupConditionInput | null,
};

export type DeleteGroupMutation = {
  deleteGroup?:  {
    __typename: "Group",
    id: string,
    name: string,
    maxMembers?: number | null,
    deletedAt?: string | null,
    enterpriseID?: string | null,
    membersCount?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    members?:  {
      __typename: "ModelGroupMemberConnection",
      items?:  Array< {
        __typename: "GroupMember",
        id: string,
        memberID: string,
        groupID: string,
        isManager?: boolean | null,
        isLearner?: boolean | null,
        userSub?: string | null,
        deletedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type UpdateGroupMutationVariables = {
  input: UpdateGroupInput,
  condition?: ModelGroupConditionInput | null,
};

export type UpdateGroupMutation = {
  updateGroup?:  {
    __typename: "Group",
    id: string,
    name: string,
    maxMembers?: number | null,
    deletedAt?: string | null,
    enterpriseID?: string | null,
    membersCount?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    members?:  {
      __typename: "ModelGroupMemberConnection",
      items?:  Array< {
        __typename: "GroupMember",
        id: string,
        memberID: string,
        groupID: string,
        isManager?: boolean | null,
        isLearner?: boolean | null,
        userSub?: string | null,
        deletedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type CreateGroupMemberMutationVariables = {
  input: CreateGroupMemberInput,
  condition?: ModelGroupMemberConditionInput | null,
};

export type CreateGroupMemberMutation = {
  createGroupMember?:  {
    __typename: "GroupMember",
    id: string,
    memberID: string,
    groupID: string,
    isManager?: boolean | null,
    isLearner?: boolean | null,
    userSub?: string | null,
    deletedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    member:  {
      __typename: "User",
      id: string,
      sub?: string | null,
      name?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      enabled?: boolean | null,
      status: Status,
      roles: Array< Role >,
      lastActive?: string | null,
      deletedAt?: string | null,
      enterpriseID?: string | null,
      phoneNumber?: string | null,
      designation?: string | null,
      optin?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      groupMemberships?:  {
        __typename: "ModelGroupMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    },
    group:  {
      __typename: "Group",
      id: string,
      name: string,
      maxMembers?: number | null,
      deletedAt?: string | null,
      enterpriseID?: string | null,
      membersCount?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      members?:  {
        __typename: "ModelGroupMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    },
  } | null,
};

export type DeleteGroupMemberMutationVariables = {
  input: DeleteGroupMemberInput,
  condition?: ModelGroupMemberConditionInput | null,
};

export type DeleteGroupMemberMutation = {
  deleteGroupMember?:  {
    __typename: "GroupMember",
    id: string,
    memberID: string,
    groupID: string,
    isManager?: boolean | null,
    isLearner?: boolean | null,
    userSub?: string | null,
    deletedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    member:  {
      __typename: "User",
      id: string,
      sub?: string | null,
      name?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      enabled?: boolean | null,
      status: Status,
      roles: Array< Role >,
      lastActive?: string | null,
      deletedAt?: string | null,
      enterpriseID?: string | null,
      phoneNumber?: string | null,
      designation?: string | null,
      optin?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      groupMemberships?:  {
        __typename: "ModelGroupMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    },
    group:  {
      __typename: "Group",
      id: string,
      name: string,
      maxMembers?: number | null,
      deletedAt?: string | null,
      enterpriseID?: string | null,
      membersCount?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      members?:  {
        __typename: "ModelGroupMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    },
  } | null,
};

export type UpdateGroupMemberMutationVariables = {
  input: UpdateGroupMemberInput,
  condition?: ModelGroupMemberConditionInput | null,
};

export type UpdateGroupMemberMutation = {
  updateGroupMember?:  {
    __typename: "GroupMember",
    id: string,
    memberID: string,
    groupID: string,
    isManager?: boolean | null,
    isLearner?: boolean | null,
    userSub?: string | null,
    deletedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    member:  {
      __typename: "User",
      id: string,
      sub?: string | null,
      name?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      enabled?: boolean | null,
      status: Status,
      roles: Array< Role >,
      lastActive?: string | null,
      deletedAt?: string | null,
      enterpriseID?: string | null,
      phoneNumber?: string | null,
      designation?: string | null,
      optin?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      groupMemberships?:  {
        __typename: "ModelGroupMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    },
    group:  {
      __typename: "Group",
      id: string,
      name: string,
      maxMembers?: number | null,
      deletedAt?: string | null,
      enterpriseID?: string | null,
      membersCount?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      members?:  {
        __typename: "ModelGroupMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    },
  } | null,
};

export type CreateEnterpriseProfileMutationVariables = {
  input: CreateEnterpriseProfileInput,
  condition?: ModelEnterpriseProfileConditionInput | null,
};

export type CreateEnterpriseProfileMutation = {
  createEnterpriseProfile?:  {
    __typename: "EnterpriseProfile",
    id: string,
    name: string,
    licenseCount: number,
    membership?:  {
      __typename: "EnterpriseMembership",
      name: string,
      status: EnterpriseMembershipStatus,
      createdAt: string,
      updatedAt: string,
    } | null,
    subscription?:  {
      __typename: "EnterpriseSubscription",
      id: string,
      provider: SubscriptionProvider,
      providerSubID: string,
      providerCustomerID: string,
      status: EnterpriseSubscriptionStatus,
      entitlements:  Array< {
        __typename: "SubscriptionEntitlement",
        featureSlug: FeatureSlug,
        featureType: FeatureType,
        featureUnit?: string | null,
        value?: string | null,
      } | null >,
      currencyCode?: string | null,
      trialStart?: string | null,
      trialEnd?: string | null,
      currentTermStart?: string | null,
      currentTermEnd?: string | null,
      nextBillingAt?: string | null,
      cancelledAt?: string | null,
      items:  Array< {
        __typename: "SubscriptionItem",
        itemPriceId: string,
        itemType: SubscriptionItemType,
        quantity?: number | null,
        unitPrice?: string | null,
        amount?: string | null,
        freeQuantity?: number | null,
        name?: string | null,
      } | null >,
      billingPeriod?: number | null,
      billingPeriodUnit?: BillingPeriodUnit | null,
      resourceVersion?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    courseContentVersion?: number | null,
    deletedAt?: string | null,
    numberOfEmployees?: string | null,
    location?: string | null,
    hiddenCourses?: Array< string > | null,
    scormCloudAppID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateEnterpriseProfileMutationVariables = {
  input: UpdateEnterpriseProfileInput,
  condition?: ModelEnterpriseProfileConditionInput | null,
};

export type UpdateEnterpriseProfileMutation = {
  updateEnterpriseProfile?:  {
    __typename: "EnterpriseProfile",
    id: string,
    name: string,
    licenseCount: number,
    membership?:  {
      __typename: "EnterpriseMembership",
      name: string,
      status: EnterpriseMembershipStatus,
      createdAt: string,
      updatedAt: string,
    } | null,
    subscription?:  {
      __typename: "EnterpriseSubscription",
      id: string,
      provider: SubscriptionProvider,
      providerSubID: string,
      providerCustomerID: string,
      status: EnterpriseSubscriptionStatus,
      entitlements:  Array< {
        __typename: "SubscriptionEntitlement",
        featureSlug: FeatureSlug,
        featureType: FeatureType,
        featureUnit?: string | null,
        value?: string | null,
      } | null >,
      currencyCode?: string | null,
      trialStart?: string | null,
      trialEnd?: string | null,
      currentTermStart?: string | null,
      currentTermEnd?: string | null,
      nextBillingAt?: string | null,
      cancelledAt?: string | null,
      items:  Array< {
        __typename: "SubscriptionItem",
        itemPriceId: string,
        itemType: SubscriptionItemType,
        quantity?: number | null,
        unitPrice?: string | null,
        amount?: string | null,
        freeQuantity?: number | null,
        name?: string | null,
      } | null >,
      billingPeriod?: number | null,
      billingPeriodUnit?: BillingPeriodUnit | null,
      resourceVersion?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    courseContentVersion?: number | null,
    deletedAt?: string | null,
    numberOfEmployees?: string | null,
    location?: string | null,
    hiddenCourses?: Array< string > | null,
    scormCloudAppID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteEnterpriseProfileMutationVariables = {
  input: DeleteEnterpriseProfileInput,
  condition?: ModelEnterpriseProfileConditionInput | null,
};

export type DeleteEnterpriseProfileMutation = {
  deleteEnterpriseProfile?:  {
    __typename: "EnterpriseProfile",
    id: string,
    name: string,
    licenseCount: number,
    membership?:  {
      __typename: "EnterpriseMembership",
      name: string,
      status: EnterpriseMembershipStatus,
      createdAt: string,
      updatedAt: string,
    } | null,
    subscription?:  {
      __typename: "EnterpriseSubscription",
      id: string,
      provider: SubscriptionProvider,
      providerSubID: string,
      providerCustomerID: string,
      status: EnterpriseSubscriptionStatus,
      entitlements:  Array< {
        __typename: "SubscriptionEntitlement",
        featureSlug: FeatureSlug,
        featureType: FeatureType,
        featureUnit?: string | null,
        value?: string | null,
      } | null >,
      currencyCode?: string | null,
      trialStart?: string | null,
      trialEnd?: string | null,
      currentTermStart?: string | null,
      currentTermEnd?: string | null,
      nextBillingAt?: string | null,
      cancelledAt?: string | null,
      items:  Array< {
        __typename: "SubscriptionItem",
        itemPriceId: string,
        itemType: SubscriptionItemType,
        quantity?: number | null,
        unitPrice?: string | null,
        amount?: string | null,
        freeQuantity?: number | null,
        name?: string | null,
      } | null >,
      billingPeriod?: number | null,
      billingPeriodUnit?: BillingPeriodUnit | null,
      resourceVersion?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    courseContentVersion?: number | null,
    deletedAt?: string | null,
    numberOfEmployees?: string | null,
    location?: string | null,
    hiddenCourses?: Array< string > | null,
    scormCloudAppID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateCourseMutationVariables = {
  input: CreateCourseInput,
  condition?: ModelCourseConditionInput | null,
};

export type CreateCourseMutation = {
  createCourse?:  {
    __typename: "Course",
    id: string,
    source: string,
    sourceID: string,
    locale: string,
    courseName: string,
    courseDescription?: string | null,
    starterPack?:  {
      __typename: "ResourceCollection",
      title: string,
      slug: string,
      items:  Array< {
        __typename: "Resource",
        resourceType: ResourceType,
      } >,
    } | null,
    active?: boolean | null,
    modules:  Array< {
      __typename: "CourseModule",
      id: string,
      source: string,
      sourceID: string,
      locale: string,
      moduleName: string,
      moduleDescription?: string | null,
      tasks:  Array< {
        __typename: "Task",
        id: string,
        taskType: TaskType,
      } >,
      createdAt: string,
      updatedAt: string,
    } >,
    tags: Array< string | null >,
    categories: Array< string | null >,
    courseTileImage?:  {
      __typename: "Link",
      title: string,
      href: string,
    } | null,
    courseHeroBannerImage?:  {
      __typename: "Link",
      title: string,
      href: string,
    } | null,
    deletedAt?: string | null,
    type?: CourseType | null,
    enterpriseID?: string | null,
    learningStandard?: CourseLearningStandard | null,
    accessType?: CourseAccessType | null,
    status?: CourseStatus | null,
    uploadType?: CourseUploadType | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCourseMutationVariables = {
  input: UpdateCourseInput,
  condition?: ModelCourseConditionInput | null,
};

export type UpdateCourseMutation = {
  updateCourse?:  {
    __typename: "Course",
    id: string,
    source: string,
    sourceID: string,
    locale: string,
    courseName: string,
    courseDescription?: string | null,
    starterPack?:  {
      __typename: "ResourceCollection",
      title: string,
      slug: string,
      items:  Array< {
        __typename: "Resource",
        resourceType: ResourceType,
      } >,
    } | null,
    active?: boolean | null,
    modules:  Array< {
      __typename: "CourseModule",
      id: string,
      source: string,
      sourceID: string,
      locale: string,
      moduleName: string,
      moduleDescription?: string | null,
      tasks:  Array< {
        __typename: "Task",
        id: string,
        taskType: TaskType,
      } >,
      createdAt: string,
      updatedAt: string,
    } >,
    tags: Array< string | null >,
    categories: Array< string | null >,
    courseTileImage?:  {
      __typename: "Link",
      title: string,
      href: string,
    } | null,
    courseHeroBannerImage?:  {
      __typename: "Link",
      title: string,
      href: string,
    } | null,
    deletedAt?: string | null,
    type?: CourseType | null,
    enterpriseID?: string | null,
    learningStandard?: CourseLearningStandard | null,
    accessType?: CourseAccessType | null,
    status?: CourseStatus | null,
    uploadType?: CourseUploadType | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCourseMutationVariables = {
  input: DeleteCourseInput,
  condition?: ModelCourseConditionInput | null,
};

export type DeleteCourseMutation = {
  deleteCourse?:  {
    __typename: "Course",
    id: string,
    source: string,
    sourceID: string,
    locale: string,
    courseName: string,
    courseDescription?: string | null,
    starterPack?:  {
      __typename: "ResourceCollection",
      title: string,
      slug: string,
      items:  Array< {
        __typename: "Resource",
        resourceType: ResourceType,
      } >,
    } | null,
    active?: boolean | null,
    modules:  Array< {
      __typename: "CourseModule",
      id: string,
      source: string,
      sourceID: string,
      locale: string,
      moduleName: string,
      moduleDescription?: string | null,
      tasks:  Array< {
        __typename: "Task",
        id: string,
        taskType: TaskType,
      } >,
      createdAt: string,
      updatedAt: string,
    } >,
    tags: Array< string | null >,
    categories: Array< string | null >,
    courseTileImage?:  {
      __typename: "Link",
      title: string,
      href: string,
    } | null,
    courseHeroBannerImage?:  {
      __typename: "Link",
      title: string,
      href: string,
    } | null,
    deletedAt?: string | null,
    type?: CourseType | null,
    enterpriseID?: string | null,
    learningStandard?: CourseLearningStandard | null,
    accessType?: CourseAccessType | null,
    status?: CourseStatus | null,
    uploadType?: CourseUploadType | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateSourceMutationVariables = {
  input: CreateSourceInput,
  condition?: ModelSourceConditionInput | null,
};

export type CreateSourceMutation = {
  createSource?:  {
    __typename: "Source",
    id: string,
    name: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateSourceMutationVariables = {
  input: UpdateSourceInput,
  condition?: ModelSourceConditionInput | null,
};

export type UpdateSourceMutation = {
  updateSource?:  {
    __typename: "Source",
    id: string,
    name: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteSourceMutationVariables = {
  input: DeleteSourceInput,
  condition?: ModelSourceConditionInput | null,
};

export type DeleteSourceMutation = {
  deleteSource?:  {
    __typename: "Source",
    id: string,
    name: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateReportMutationVariables = {
  input: CreateReportInput,
  condition?: ModelReportConditionInput | null,
};

export type CreateReportMutation = {
  createReport?:  {
    __typename: "Report",
    id: string,
    sourceReferenceID: string,
    sourceID: string,
    locale: string,
    name: string,
    heading?: string | null,
    subHeading?: string | null,
    startCTA?: string | null,
    explainer?: string | null,
    assessment:  {
      __typename: "AssignmentTask",
      source: AssignmentSource,
      title: string,
      formId?: string | null,
    },
    skills:  Array< {
      __typename: "Skill",
      id: string,
      label: string,
      skillReport?:  {
        __typename: "Link",
        title: string,
        href: string,
      } | null,
      iconLink?:  {
        __typename: "Link",
        title: string,
        href: string,
      } | null,
      selectedIconLink?:  {
        __typename: "Link",
        title: string,
        href: string,
      } | null,
      subSkills:  Array< {
        __typename: "SubSkill",
        id: string,
        label: string,
        description?: string | null,
      } >,
    } >,
    deletedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    source?:  {
      __typename: "Source",
      id: string,
      name: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type UpdateReportMutationVariables = {
  input: UpdateReportInput,
  condition?: ModelReportConditionInput | null,
};

export type UpdateReportMutation = {
  updateReport?:  {
    __typename: "Report",
    id: string,
    sourceReferenceID: string,
    sourceID: string,
    locale: string,
    name: string,
    heading?: string | null,
    subHeading?: string | null,
    startCTA?: string | null,
    explainer?: string | null,
    assessment:  {
      __typename: "AssignmentTask",
      source: AssignmentSource,
      title: string,
      formId?: string | null,
    },
    skills:  Array< {
      __typename: "Skill",
      id: string,
      label: string,
      skillReport?:  {
        __typename: "Link",
        title: string,
        href: string,
      } | null,
      iconLink?:  {
        __typename: "Link",
        title: string,
        href: string,
      } | null,
      selectedIconLink?:  {
        __typename: "Link",
        title: string,
        href: string,
      } | null,
      subSkills:  Array< {
        __typename: "SubSkill",
        id: string,
        label: string,
        description?: string | null,
      } >,
    } >,
    deletedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    source?:  {
      __typename: "Source",
      id: string,
      name: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type DeleteReportMutationVariables = {
  input: DeleteReportInput,
  condition?: ModelReportConditionInput | null,
};

export type DeleteReportMutation = {
  deleteReport?:  {
    __typename: "Report",
    id: string,
    sourceReferenceID: string,
    sourceID: string,
    locale: string,
    name: string,
    heading?: string | null,
    subHeading?: string | null,
    startCTA?: string | null,
    explainer?: string | null,
    assessment:  {
      __typename: "AssignmentTask",
      source: AssignmentSource,
      title: string,
      formId?: string | null,
    },
    skills:  Array< {
      __typename: "Skill",
      id: string,
      label: string,
      skillReport?:  {
        __typename: "Link",
        title: string,
        href: string,
      } | null,
      iconLink?:  {
        __typename: "Link",
        title: string,
        href: string,
      } | null,
      selectedIconLink?:  {
        __typename: "Link",
        title: string,
        href: string,
      } | null,
      subSkills:  Array< {
        __typename: "SubSkill",
        id: string,
        label: string,
        description?: string | null,
      } >,
    } >,
    deletedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    source?:  {
      __typename: "Source",
      id: string,
      name: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type CreateReportResultMutationVariables = {
  input: CreateReportResultInput,
  condition?: ModelReportResultConditionInput | null,
};

export type CreateReportResultMutation = {
  createReportResult?:  {
    __typename: "ReportResult",
    id: string,
    reportID: string,
    userID: string,
    userSub: string,
    formID: string,
    responseID: string,
    skillScores:  Array< {
      __typename: "SkillScore",
      skillID: string,
      score: number,
      maxScore: number,
      subSkillScores:  Array< {
        __typename: "SubSkillScore",
        subSkillID: string,
        score: number,
        maxScore: number,
        scoreKey: string,
      } >,
    } >,
    deletedAt?: string | null,
    enterpriseID: string,
    archived?: boolean | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    user?:  {
      __typename: "User",
      id: string,
      sub?: string | null,
      name?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      enabled?: boolean | null,
      status: Status,
      roles: Array< Role >,
      lastActive?: string | null,
      deletedAt?: string | null,
      enterpriseID?: string | null,
      phoneNumber?: string | null,
      designation?: string | null,
      optin?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      groupMemberships?:  {
        __typename: "ModelGroupMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
    report?:  {
      __typename: "Report",
      id: string,
      sourceReferenceID: string,
      sourceID: string,
      locale: string,
      name: string,
      heading?: string | null,
      subHeading?: string | null,
      startCTA?: string | null,
      explainer?: string | null,
      assessment:  {
        __typename: "AssignmentTask",
        source: AssignmentSource,
        title: string,
        formId?: string | null,
      },
      skills:  Array< {
        __typename: "Skill",
        id: string,
        label: string,
      } >,
      deletedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      source?:  {
        __typename: "Source",
        id: string,
        name: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
  } | null,
};

export type UpdateReportResultMutationVariables = {
  input: UpdateReportResultInput,
  condition?: ModelReportResultConditionInput | null,
};

export type UpdateReportResultMutation = {
  updateReportResult?:  {
    __typename: "ReportResult",
    id: string,
    reportID: string,
    userID: string,
    userSub: string,
    formID: string,
    responseID: string,
    skillScores:  Array< {
      __typename: "SkillScore",
      skillID: string,
      score: number,
      maxScore: number,
      subSkillScores:  Array< {
        __typename: "SubSkillScore",
        subSkillID: string,
        score: number,
        maxScore: number,
        scoreKey: string,
      } >,
    } >,
    deletedAt?: string | null,
    enterpriseID: string,
    archived?: boolean | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    user?:  {
      __typename: "User",
      id: string,
      sub?: string | null,
      name?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      enabled?: boolean | null,
      status: Status,
      roles: Array< Role >,
      lastActive?: string | null,
      deletedAt?: string | null,
      enterpriseID?: string | null,
      phoneNumber?: string | null,
      designation?: string | null,
      optin?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      groupMemberships?:  {
        __typename: "ModelGroupMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
    report?:  {
      __typename: "Report",
      id: string,
      sourceReferenceID: string,
      sourceID: string,
      locale: string,
      name: string,
      heading?: string | null,
      subHeading?: string | null,
      startCTA?: string | null,
      explainer?: string | null,
      assessment:  {
        __typename: "AssignmentTask",
        source: AssignmentSource,
        title: string,
        formId?: string | null,
      },
      skills:  Array< {
        __typename: "Skill",
        id: string,
        label: string,
      } >,
      deletedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      source?:  {
        __typename: "Source",
        id: string,
        name: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
  } | null,
};

export type DeleteReportResultMutationVariables = {
  input: DeleteReportResultInput,
  condition?: ModelReportResultConditionInput | null,
};

export type DeleteReportResultMutation = {
  deleteReportResult?:  {
    __typename: "ReportResult",
    id: string,
    reportID: string,
    userID: string,
    userSub: string,
    formID: string,
    responseID: string,
    skillScores:  Array< {
      __typename: "SkillScore",
      skillID: string,
      score: number,
      maxScore: number,
      subSkillScores:  Array< {
        __typename: "SubSkillScore",
        subSkillID: string,
        score: number,
        maxScore: number,
        scoreKey: string,
      } >,
    } >,
    deletedAt?: string | null,
    enterpriseID: string,
    archived?: boolean | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    user?:  {
      __typename: "User",
      id: string,
      sub?: string | null,
      name?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      enabled?: boolean | null,
      status: Status,
      roles: Array< Role >,
      lastActive?: string | null,
      deletedAt?: string | null,
      enterpriseID?: string | null,
      phoneNumber?: string | null,
      designation?: string | null,
      optin?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      groupMemberships?:  {
        __typename: "ModelGroupMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
    report?:  {
      __typename: "Report",
      id: string,
      sourceReferenceID: string,
      sourceID: string,
      locale: string,
      name: string,
      heading?: string | null,
      subHeading?: string | null,
      startCTA?: string | null,
      explainer?: string | null,
      assessment:  {
        __typename: "AssignmentTask",
        source: AssignmentSource,
        title: string,
        formId?: string | null,
      },
      skills:  Array< {
        __typename: "Skill",
        id: string,
        label: string,
      } >,
      deletedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      source?:  {
        __typename: "Source",
        id: string,
        name: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
  } | null,
};

export type CreateReportSubmissionMutationVariables = {
  input: CreateReportSubmissionInput,
  condition?: ModelReportSubmissionConditionInput | null,
};

export type CreateReportSubmissionMutation = {
  createReportSubmission?:  {
    __typename: "ReportSubmission",
    id: string,
    reportID: string,
    userID: string,
    userSub: string,
    status: ReportSubmissionStatus,
    deletedAt?: string | null,
    enterpriseID: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateReportSubmissionMutationVariables = {
  input: UpdateReportSubmissionInput,
  condition?: ModelReportSubmissionConditionInput | null,
};

export type UpdateReportSubmissionMutation = {
  updateReportSubmission?:  {
    __typename: "ReportSubmission",
    id: string,
    reportID: string,
    userID: string,
    userSub: string,
    status: ReportSubmissionStatus,
    deletedAt?: string | null,
    enterpriseID: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteReportSubmissionMutationVariables = {
  input: DeleteReportSubmissionInput,
  condition?: ModelReportSubmissionConditionInput | null,
};

export type DeleteReportSubmissionMutation = {
  deleteReportSubmission?:  {
    __typename: "ReportSubmission",
    id: string,
    reportID: string,
    userID: string,
    userSub: string,
    status: ReportSubmissionStatus,
    deletedAt?: string | null,
    enterpriseID: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateTypeformResponseMutationVariables = {
  input: CreateTypeformResponseInput,
  condition?: ModelTypeformResponseConditionInput | null,
};

export type CreateTypeformResponseMutation = {
  createTypeformResponse?:  {
    __typename: "TypeformResponse",
    id: string,
    formID: string,
    userID?: string | null,
    assessmentType?: string | null,
    payload: string,
    deletedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateTypeformResponseMutationVariables = {
  input: UpdateTypeformResponseInput,
  condition?: ModelTypeformResponseConditionInput | null,
};

export type UpdateTypeformResponseMutation = {
  updateTypeformResponse?:  {
    __typename: "TypeformResponse",
    id: string,
    formID: string,
    userID?: string | null,
    assessmentType?: string | null,
    payload: string,
    deletedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteTypeformResponseMutationVariables = {
  input: DeleteTypeformResponseInput,
  condition?: ModelTypeformResponseConditionInput | null,
};

export type DeleteTypeformResponseMutation = {
  deleteTypeformResponse?:  {
    __typename: "TypeformResponse",
    id: string,
    formID: string,
    userID?: string | null,
    assessmentType?: string | null,
    payload: string,
    deletedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateCourseRegistrationMutationVariables = {
  input: CreateCourseRegistrationInput,
  condition?: ModelCourseRegistrationConditionInput | null,
};

export type CreateCourseRegistrationMutation = {
  createCourseRegistration?:  {
    __typename: "CourseRegistration",
    id: string,
    courseID: string,
    userID: string,
    userSub?: string | null,
    status: CourseRegistrationStatus,
    deadline?: string | null,
    deletedAt?: string | null,
    enterpriseID?: string | null,
    assignedByUserID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCourseRegistrationMutationVariables = {
  input: UpdateCourseRegistrationInput,
  condition?: ModelCourseRegistrationConditionInput | null,
};

export type UpdateCourseRegistrationMutation = {
  updateCourseRegistration?:  {
    __typename: "CourseRegistration",
    id: string,
    courseID: string,
    userID: string,
    userSub?: string | null,
    status: CourseRegistrationStatus,
    deadline?: string | null,
    deletedAt?: string | null,
    enterpriseID?: string | null,
    assignedByUserID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCourseRegistrationMutationVariables = {
  input: DeleteCourseRegistrationInput,
  condition?: ModelCourseRegistrationConditionInput | null,
};

export type DeleteCourseRegistrationMutation = {
  deleteCourseRegistration?:  {
    __typename: "CourseRegistration",
    id: string,
    courseID: string,
    userID: string,
    userSub?: string | null,
    status: CourseRegistrationStatus,
    deadline?: string | null,
    deletedAt?: string | null,
    enterpriseID?: string | null,
    assignedByUserID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateCourseRegistrationActivityMutationVariables = {
  input: CreateCourseRegistrationActivityInput,
  condition?: ModelCourseRegistrationActivityConditionInput | null,
};

export type CreateCourseRegistrationActivityMutation = {
  createCourseRegistrationActivity?:  {
    __typename: "CourseRegistrationActivity",
    id: string,
    courseRegistrationID: string,
    userID: string,
    userSub?: string | null,
    taskID: string,
    taskType: TaskType,
    courseID: string,
    status: CourseRegistrationActivityStatus,
    deletedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    user?:  {
      __typename: "User",
      id: string,
      sub?: string | null,
      name?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      enabled?: boolean | null,
      status: Status,
      roles: Array< Role >,
      lastActive?: string | null,
      deletedAt?: string | null,
      enterpriseID?: string | null,
      phoneNumber?: string | null,
      designation?: string | null,
      optin?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      groupMemberships?:  {
        __typename: "ModelGroupMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
    courseRegistration?:  {
      __typename: "CourseRegistration",
      id: string,
      courseID: string,
      userID: string,
      userSub?: string | null,
      status: CourseRegistrationStatus,
      deadline?: string | null,
      deletedAt?: string | null,
      enterpriseID?: string | null,
      assignedByUserID?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type UpdateCourseRegistrationActivityMutationVariables = {
  input: UpdateCourseRegistrationActivityInput,
  condition?: ModelCourseRegistrationActivityConditionInput | null,
};

export type UpdateCourseRegistrationActivityMutation = {
  updateCourseRegistrationActivity?:  {
    __typename: "CourseRegistrationActivity",
    id: string,
    courseRegistrationID: string,
    userID: string,
    userSub?: string | null,
    taskID: string,
    taskType: TaskType,
    courseID: string,
    status: CourseRegistrationActivityStatus,
    deletedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    user?:  {
      __typename: "User",
      id: string,
      sub?: string | null,
      name?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      enabled?: boolean | null,
      status: Status,
      roles: Array< Role >,
      lastActive?: string | null,
      deletedAt?: string | null,
      enterpriseID?: string | null,
      phoneNumber?: string | null,
      designation?: string | null,
      optin?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      groupMemberships?:  {
        __typename: "ModelGroupMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
    courseRegistration?:  {
      __typename: "CourseRegistration",
      id: string,
      courseID: string,
      userID: string,
      userSub?: string | null,
      status: CourseRegistrationStatus,
      deadline?: string | null,
      deletedAt?: string | null,
      enterpriseID?: string | null,
      assignedByUserID?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type DeleteCourseRegistrationActivityMutationVariables = {
  input: DeleteCourseRegistrationActivityInput,
  condition?: ModelCourseRegistrationActivityConditionInput | null,
};

export type DeleteCourseRegistrationActivityMutation = {
  deleteCourseRegistrationActivity?:  {
    __typename: "CourseRegistrationActivity",
    id: string,
    courseRegistrationID: string,
    userID: string,
    userSub?: string | null,
    taskID: string,
    taskType: TaskType,
    courseID: string,
    status: CourseRegistrationActivityStatus,
    deletedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    user?:  {
      __typename: "User",
      id: string,
      sub?: string | null,
      name?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      enabled?: boolean | null,
      status: Status,
      roles: Array< Role >,
      lastActive?: string | null,
      deletedAt?: string | null,
      enterpriseID?: string | null,
      phoneNumber?: string | null,
      designation?: string | null,
      optin?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      groupMemberships?:  {
        __typename: "ModelGroupMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
    courseRegistration?:  {
      __typename: "CourseRegistration",
      id: string,
      courseID: string,
      userID: string,
      userSub?: string | null,
      status: CourseRegistrationStatus,
      deadline?: string | null,
      deletedAt?: string | null,
      enterpriseID?: string | null,
      assignedByUserID?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type CreateAssignmentResponseMutationVariables = {
  input: CreateAssignmentResponseInput,
  condition?: ModelAssignmentResponseConditionInput | null,
};

export type CreateAssignmentResponseMutation = {
  createAssignmentResponse?:  {
    __typename: "AssignmentResponse",
    id: string,
    courseRegistrationID: string,
    userID: string,
    userSub: string,
    taskID: string,
    source: AssignmentSource,
    formID: string,
    responseID: string,
    score: number,
    totalScore: number,
    deletedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateAssignmentResponseMutationVariables = {
  input: UpdateAssignmentResponseInput,
  condition?: ModelAssignmentResponseConditionInput | null,
};

export type UpdateAssignmentResponseMutation = {
  updateAssignmentResponse?:  {
    __typename: "AssignmentResponse",
    id: string,
    courseRegistrationID: string,
    userID: string,
    userSub: string,
    taskID: string,
    source: AssignmentSource,
    formID: string,
    responseID: string,
    score: number,
    totalScore: number,
    deletedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteAssignmentResponseMutationVariables = {
  input: DeleteAssignmentResponseInput,
  condition?: ModelAssignmentResponseConditionInput | null,
};

export type DeleteAssignmentResponseMutation = {
  deleteAssignmentResponse?:  {
    __typename: "AssignmentResponse",
    id: string,
    courseRegistrationID: string,
    userID: string,
    userSub: string,
    taskID: string,
    source: AssignmentSource,
    formID: string,
    responseID: string,
    score: number,
    totalScore: number,
    deletedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateNotificationMutationVariables = {
  input: CreateNotificationInput,
  condition?: ModelNotificationConditionInput | null,
};

export type CreateNotificationMutation = {
  createNotification?:  {
    __typename: "Notification",
    id: string,
    userID: string,
    userSub: string,
    title: string,
    message?: string | null,
    read?: boolean | null,
    deletedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    user?:  {
      __typename: "User",
      id: string,
      sub?: string | null,
      name?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      enabled?: boolean | null,
      status: Status,
      roles: Array< Role >,
      lastActive?: string | null,
      deletedAt?: string | null,
      enterpriseID?: string | null,
      phoneNumber?: string | null,
      designation?: string | null,
      optin?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      groupMemberships?:  {
        __typename: "ModelGroupMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateNotificationMutationVariables = {
  input: UpdateNotificationInput,
  condition?: ModelNotificationConditionInput | null,
};

export type UpdateNotificationMutation = {
  updateNotification?:  {
    __typename: "Notification",
    id: string,
    userID: string,
    userSub: string,
    title: string,
    message?: string | null,
    read?: boolean | null,
    deletedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    user?:  {
      __typename: "User",
      id: string,
      sub?: string | null,
      name?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      enabled?: boolean | null,
      status: Status,
      roles: Array< Role >,
      lastActive?: string | null,
      deletedAt?: string | null,
      enterpriseID?: string | null,
      phoneNumber?: string | null,
      designation?: string | null,
      optin?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      groupMemberships?:  {
        __typename: "ModelGroupMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteNotificationMutationVariables = {
  input: DeleteNotificationInput,
  condition?: ModelNotificationConditionInput | null,
};

export type DeleteNotificationMutation = {
  deleteNotification?:  {
    __typename: "Notification",
    id: string,
    userID: string,
    userSub: string,
    title: string,
    message?: string | null,
    read?: boolean | null,
    deletedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    user?:  {
      __typename: "User",
      id: string,
      sub?: string | null,
      name?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      enabled?: boolean | null,
      status: Status,
      roles: Array< Role >,
      lastActive?: string | null,
      deletedAt?: string | null,
      enterpriseID?: string | null,
      phoneNumber?: string | null,
      designation?: string | null,
      optin?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      groupMemberships?:  {
        __typename: "ModelGroupMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
  } | null,
};

export type CreateInvitationMutationVariables = {
  input: CreateInvitationInput,
  condition?: ModelInvitationConditionInput | null,
};

export type CreateInvitationMutation = {
  createInvitation?:  {
    __typename: "Invitation",
    id: string,
    email: string,
    status: InvitationStatus,
    inviterID: string,
    inviterEnterpriseID: string,
    inviteeID?: string | null,
    roles?: Array< Role | null > | null,
    groupID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    inviter?:  {
      __typename: "User",
      id: string,
      sub?: string | null,
      name?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      enabled?: boolean | null,
      status: Status,
      roles: Array< Role >,
      lastActive?: string | null,
      deletedAt?: string | null,
      enterpriseID?: string | null,
      phoneNumber?: string | null,
      designation?: string | null,
      optin?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      groupMemberships?:  {
        __typename: "ModelGroupMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateInvitationMutationVariables = {
  input: UpdateInvitationInput,
  condition?: ModelInvitationConditionInput | null,
};

export type UpdateInvitationMutation = {
  updateInvitation?:  {
    __typename: "Invitation",
    id: string,
    email: string,
    status: InvitationStatus,
    inviterID: string,
    inviterEnterpriseID: string,
    inviteeID?: string | null,
    roles?: Array< Role | null > | null,
    groupID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    inviter?:  {
      __typename: "User",
      id: string,
      sub?: string | null,
      name?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      enabled?: boolean | null,
      status: Status,
      roles: Array< Role >,
      lastActive?: string | null,
      deletedAt?: string | null,
      enterpriseID?: string | null,
      phoneNumber?: string | null,
      designation?: string | null,
      optin?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      groupMemberships?:  {
        __typename: "ModelGroupMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteInvitationMutationVariables = {
  input: DeleteInvitationInput,
  condition?: ModelInvitationConditionInput | null,
};

export type DeleteInvitationMutation = {
  deleteInvitation?:  {
    __typename: "Invitation",
    id: string,
    email: string,
    status: InvitationStatus,
    inviterID: string,
    inviterEnterpriseID: string,
    inviteeID?: string | null,
    roles?: Array< Role | null > | null,
    groupID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    inviter?:  {
      __typename: "User",
      id: string,
      sub?: string | null,
      name?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      enabled?: boolean | null,
      status: Status,
      roles: Array< Role >,
      lastActive?: string | null,
      deletedAt?: string | null,
      enterpriseID?: string | null,
      phoneNumber?: string | null,
      designation?: string | null,
      optin?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      groupMemberships?:  {
        __typename: "ModelGroupMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
  } | null,
};

export type AdminListUsersByEnterpriseIDQueryVariables = {
  enterpriseID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AdminListUsersByEnterpriseIDQuery = {
  adminListUsersByEnterpriseID?:  {
    __typename: "ModelUserConnection",
    items?:  Array< {
      __typename: "User",
      id: string,
      sub?: string | null,
      name?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      enabled?: boolean | null,
      status: Status,
      roles: Array< Role >,
      lastActive?: string | null,
      deletedAt?: string | null,
      enterpriseID?: string | null,
      phoneNumber?: string | null,
      designation?: string | null,
      optin?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      groupMemberships?:  {
        __typename: "ModelGroupMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type AdminListGroupsByEnterpriseIDQueryVariables = {
  enterpriseID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AdminListGroupsByEnterpriseIDQuery = {
  adminListGroupsByEnterpriseID?:  {
    __typename: "ModelGroupConnection",
    items?:  Array< {
      __typename: "Group",
      id: string,
      name: string,
      maxMembers?: number | null,
      deletedAt?: string | null,
      enterpriseID?: string | null,
      membersCount?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      members?:  {
        __typename: "ModelGroupMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListInvitationsByEnterpriseIDQueryVariables = {
  inviterEnterpriseID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelInvitationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListInvitationsByEnterpriseIDQuery = {
  listInvitationsByEnterpriseID?:  {
    __typename: "ModelInvitationConnection",
    items?:  Array< {
      __typename: "Invitation",
      id: string,
      email: string,
      status: InvitationStatus,
      inviterID: string,
      inviterEnterpriseID: string,
      inviteeID?: string | null,
      roles?: Array< Role | null > | null,
      groupID?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      inviter?:  {
        __typename: "User",
        id: string,
        sub?: string | null,
        name?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email: string,
        enabled?: boolean | null,
        status: Status,
        roles: Array< Role >,
        lastActive?: string | null,
        deletedAt?: string | null,
        enterpriseID?: string | null,
        phoneNumber?: string | null,
        designation?: string | null,
        optin?: boolean | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type AdminGetUserQueryVariables = {
  id: string,
};

export type AdminGetUserQuery = {
  adminGetUser?:  {
    __typename: "User",
    id: string,
    sub?: string | null,
    name?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    email: string,
    enabled?: boolean | null,
    status: Status,
    roles: Array< Role >,
    lastActive?: string | null,
    deletedAt?: string | null,
    enterpriseID?: string | null,
    phoneNumber?: string | null,
    designation?: string | null,
    optin?: boolean | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    groupMemberships?:  {
      __typename: "ModelGroupMemberConnection",
      items?:  Array< {
        __typename: "GroupMember",
        id: string,
        memberID: string,
        groupID: string,
        isManager?: boolean | null,
        isLearner?: boolean | null,
        userSub?: string | null,
        deletedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type AdminGetGroupQueryVariables = {
  id: string,
};

export type AdminGetGroupQuery = {
  adminGetGroup?:  {
    __typename: "Group",
    id: string,
    name: string,
    maxMembers?: number | null,
    deletedAt?: string | null,
    enterpriseID?: string | null,
    membersCount?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    members?:  {
      __typename: "ModelGroupMemberConnection",
      items?:  Array< {
        __typename: "GroupMember",
        id: string,
        memberID: string,
        groupID: string,
        isManager?: boolean | null,
        isLearner?: boolean | null,
        userSub?: string | null,
        deletedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type AdminGetGroupMemberQueryVariables = {
  id: string,
};

export type AdminGetGroupMemberQuery = {
  adminGetGroupMember?:  {
    __typename: "GroupMember",
    id: string,
    memberID: string,
    groupID: string,
    isManager?: boolean | null,
    isLearner?: boolean | null,
    userSub?: string | null,
    deletedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    member:  {
      __typename: "User",
      id: string,
      sub?: string | null,
      name?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      enabled?: boolean | null,
      status: Status,
      roles: Array< Role >,
      lastActive?: string | null,
      deletedAt?: string | null,
      enterpriseID?: string | null,
      phoneNumber?: string | null,
      designation?: string | null,
      optin?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      groupMemberships?:  {
        __typename: "ModelGroupMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    },
    group:  {
      __typename: "Group",
      id: string,
      name: string,
      maxMembers?: number | null,
      deletedAt?: string | null,
      enterpriseID?: string | null,
      membersCount?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      members?:  {
        __typename: "ModelGroupMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    },
  } | null,
};

export type AdminListEnterpriseProfilesQueryVariables = {
  filter?: ModelEnterpriseProfileFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AdminListEnterpriseProfilesQuery = {
  adminListEnterpriseProfiles?:  {
    __typename: "ModelEnterpriseProfileConnection",
    items?:  Array< {
      __typename: "EnterpriseProfile",
      id: string,
      name: string,
      licenseCount: number,
      membership?:  {
        __typename: "EnterpriseMembership",
        name: string,
        status: EnterpriseMembershipStatus,
        createdAt: string,
        updatedAt: string,
      } | null,
      subscription?:  {
        __typename: "EnterpriseSubscription",
        id: string,
        provider: SubscriptionProvider,
        providerSubID: string,
        providerCustomerID: string,
        status: EnterpriseSubscriptionStatus,
        currencyCode?: string | null,
        trialStart?: string | null,
        trialEnd?: string | null,
        currentTermStart?: string | null,
        currentTermEnd?: string | null,
        nextBillingAt?: string | null,
        cancelledAt?: string | null,
        billingPeriod?: number | null,
        billingPeriodUnit?: BillingPeriodUnit | null,
        resourceVersion?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      courseContentVersion?: number | null,
      deletedAt?: string | null,
      numberOfEmployees?: string | null,
      location?: string | null,
      hiddenCourses?: Array< string > | null,
      scormCloudAppID?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type AdminSyncEnterpriseProfilesQueryVariables = {
  filter?: ModelEnterpriseProfileFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type AdminSyncEnterpriseProfilesQuery = {
  adminSyncEnterpriseProfiles?:  {
    __typename: "ModelEnterpriseProfileConnection",
    items?:  Array< {
      __typename: "EnterpriseProfile",
      id: string,
      name: string,
      licenseCount: number,
      membership?:  {
        __typename: "EnterpriseMembership",
        name: string,
        status: EnterpriseMembershipStatus,
        createdAt: string,
        updatedAt: string,
      } | null,
      subscription?:  {
        __typename: "EnterpriseSubscription",
        id: string,
        provider: SubscriptionProvider,
        providerSubID: string,
        providerCustomerID: string,
        status: EnterpriseSubscriptionStatus,
        currencyCode?: string | null,
        trialStart?: string | null,
        trialEnd?: string | null,
        currentTermStart?: string | null,
        currentTermEnd?: string | null,
        nextBillingAt?: string | null,
        cancelledAt?: string | null,
        billingPeriod?: number | null,
        billingPeriodUnit?: BillingPeriodUnit | null,
        resourceVersion?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      courseContentVersion?: number | null,
      deletedAt?: string | null,
      numberOfEmployees?: string | null,
      location?: string | null,
      hiddenCourses?: Array< string > | null,
      scormCloudAppID?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetRBACMatrixQueryVariables = {
  sub?: string | null,
};

export type GetRBACMatrixQuery = {
  getRBACMatrix?:  {
    __typename: "RbacMatrixConnection",
    items?:  Array< {
      __typename: "RbacMatrix",
      resource?:  {
        __typename: "RbacResource",
        type?: string | null,
        id?: string | null,
      } | null,
      roles?: Array< Role | null > | null,
      userID?: string | null,
    } | null > | null,
  } | null,
};

export type AdminListReportResultsByEnterpriseIDQueryVariables = {
  enterpriseID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReportResultFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AdminListReportResultsByEnterpriseIDQuery = {
  adminListReportResultsByEnterpriseID?:  {
    __typename: "ModelReportResultConnection",
    items?:  Array< {
      __typename: "ReportResult",
      id: string,
      reportID: string,
      userID: string,
      userSub: string,
      formID: string,
      responseID: string,
      skillScores:  Array< {
        __typename: "SkillScore",
        skillID: string,
        score: number,
        maxScore: number,
      } >,
      deletedAt?: string | null,
      enterpriseID: string,
      archived?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      user?:  {
        __typename: "User",
        id: string,
        sub?: string | null,
        name?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email: string,
        enabled?: boolean | null,
        status: Status,
        roles: Array< Role >,
        lastActive?: string | null,
        deletedAt?: string | null,
        enterpriseID?: string | null,
        phoneNumber?: string | null,
        designation?: string | null,
        optin?: boolean | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null,
      report?:  {
        __typename: "Report",
        id: string,
        sourceReferenceID: string,
        sourceID: string,
        locale: string,
        name: string,
        heading?: string | null,
        subHeading?: string | null,
        startCTA?: string | null,
        explainer?: string | null,
        deletedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type AdminGetReportResultQueryVariables = {
  id: string,
};

export type AdminGetReportResultQuery = {
  adminGetReportResult?:  {
    __typename: "ReportResult",
    id: string,
    reportID: string,
    userID: string,
    userSub: string,
    formID: string,
    responseID: string,
    skillScores:  Array< {
      __typename: "SkillScore",
      skillID: string,
      score: number,
      maxScore: number,
      subSkillScores:  Array< {
        __typename: "SubSkillScore",
        subSkillID: string,
        score: number,
        maxScore: number,
        scoreKey: string,
      } >,
    } >,
    deletedAt?: string | null,
    enterpriseID: string,
    archived?: boolean | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    user?:  {
      __typename: "User",
      id: string,
      sub?: string | null,
      name?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      enabled?: boolean | null,
      status: Status,
      roles: Array< Role >,
      lastActive?: string | null,
      deletedAt?: string | null,
      enterpriseID?: string | null,
      phoneNumber?: string | null,
      designation?: string | null,
      optin?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      groupMemberships?:  {
        __typename: "ModelGroupMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
    report?:  {
      __typename: "Report",
      id: string,
      sourceReferenceID: string,
      sourceID: string,
      locale: string,
      name: string,
      heading?: string | null,
      subHeading?: string | null,
      startCTA?: string | null,
      explainer?: string | null,
      assessment:  {
        __typename: "AssignmentTask",
        source: AssignmentSource,
        title: string,
        formId?: string | null,
      },
      skills:  Array< {
        __typename: "Skill",
        id: string,
        label: string,
      } >,
      deletedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      source?:  {
        __typename: "Source",
        id: string,
        name: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
  } | null,
};

export type AdminListCourseRegistrationsByEnterpriseIDQueryVariables = {
  enterpriseID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCourseRegistrationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AdminListCourseRegistrationsByEnterpriseIDQuery = {
  adminListCourseRegistrationsByEnterpriseID?:  {
    __typename: "ModelCourseRegistrationConnection",
    items?:  Array< {
      __typename: "CourseRegistration",
      id: string,
      courseID: string,
      userID: string,
      userSub?: string | null,
      status: CourseRegistrationStatus,
      deadline?: string | null,
      deletedAt?: string | null,
      enterpriseID?: string | null,
      assignedByUserID?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListPrivateCoursesByEnterpriseIDQueryVariables = {
  enterpriseID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCourseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPrivateCoursesByEnterpriseIDQuery = {
  listPrivateCoursesByEnterpriseID?:  {
    __typename: "ModelCourseConnection",
    items?:  Array< {
      __typename: "Course",
      id: string,
      source: string,
      sourceID: string,
      locale: string,
      courseName: string,
      courseDescription?: string | null,
      starterPack?:  {
        __typename: "ResourceCollection",
        title: string,
        slug: string,
      } | null,
      active?: boolean | null,
      modules:  Array< {
        __typename: "CourseModule",
        id: string,
        source: string,
        sourceID: string,
        locale: string,
        moduleName: string,
        moduleDescription?: string | null,
        createdAt: string,
        updatedAt: string,
      } >,
      tags: Array< string | null >,
      categories: Array< string | null >,
      courseTileImage?:  {
        __typename: "Link",
        title: string,
        href: string,
      } | null,
      courseHeroBannerImage?:  {
        __typename: "Link",
        title: string,
        href: string,
      } | null,
      deletedAt?: string | null,
      type?: CourseType | null,
      enterpriseID?: string | null,
      learningStandard?: CourseLearningStandard | null,
      accessType?: CourseAccessType | null,
      status?: CourseStatus | null,
      uploadType?: CourseUploadType | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListPublicCoursesQueryVariables = {
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCourseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPublicCoursesQuery = {
  listPublicCourses?:  {
    __typename: "ModelCourseConnection",
    items?:  Array< {
      __typename: "Course",
      id: string,
      source: string,
      sourceID: string,
      locale: string,
      courseName: string,
      courseDescription?: string | null,
      starterPack?:  {
        __typename: "ResourceCollection",
        title: string,
        slug: string,
      } | null,
      active?: boolean | null,
      modules:  Array< {
        __typename: "CourseModule",
        id: string,
        source: string,
        sourceID: string,
        locale: string,
        moduleName: string,
        moduleDescription?: string | null,
        createdAt: string,
        updatedAt: string,
      } >,
      tags: Array< string | null >,
      categories: Array< string | null >,
      courseTileImage?:  {
        __typename: "Link",
        title: string,
        href: string,
      } | null,
      courseHeroBannerImage?:  {
        __typename: "Link",
        title: string,
        href: string,
      } | null,
      deletedAt?: string | null,
      type?: CourseType | null,
      enterpriseID?: string | null,
      learningStandard?: CourseLearningStandard | null,
      accessType?: CourseAccessType | null,
      status?: CourseStatus | null,
      uploadType?: CourseUploadType | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetUserQueryVariables = {
  id: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    id: string,
    sub?: string | null,
    name?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    email: string,
    enabled?: boolean | null,
    status: Status,
    roles: Array< Role >,
    lastActive?: string | null,
    deletedAt?: string | null,
    enterpriseID?: string | null,
    phoneNumber?: string | null,
    designation?: string | null,
    optin?: boolean | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    groupMemberships?:  {
      __typename: "ModelGroupMemberConnection",
      items?:  Array< {
        __typename: "GroupMember",
        id: string,
        memberID: string,
        groupID: string,
        isManager?: boolean | null,
        isLearner?: boolean | null,
        userSub?: string | null,
        deletedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type ListUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items?:  Array< {
      __typename: "User",
      id: string,
      sub?: string | null,
      name?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      enabled?: boolean | null,
      status: Status,
      roles: Array< Role >,
      lastActive?: string | null,
      deletedAt?: string | null,
      enterpriseID?: string | null,
      phoneNumber?: string | null,
      designation?: string | null,
      optin?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      groupMemberships?:  {
        __typename: "ModelGroupMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListUsersBySubQueryVariables = {
  sub?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersBySubQuery = {
  listUsersBySub?:  {
    __typename: "ModelUserConnection",
    items?:  Array< {
      __typename: "User",
      id: string,
      sub?: string | null,
      name?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      enabled?: boolean | null,
      status: Status,
      roles: Array< Role >,
      lastActive?: string | null,
      deletedAt?: string | null,
      enterpriseID?: string | null,
      phoneNumber?: string | null,
      designation?: string | null,
      optin?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      groupMemberships?:  {
        __typename: "ModelGroupMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListUsersByEnterpriseIDQueryVariables = {
  enterpriseID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersByEnterpriseIDQuery = {
  listUsersByEnterpriseID?:  {
    __typename: "ModelUserConnection",
    items?:  Array< {
      __typename: "User",
      id: string,
      sub?: string | null,
      name?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      enabled?: boolean | null,
      status: Status,
      roles: Array< Role >,
      lastActive?: string | null,
      deletedAt?: string | null,
      enterpriseID?: string | null,
      phoneNumber?: string | null,
      designation?: string | null,
      optin?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      groupMemberships?:  {
        __typename: "ModelGroupMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncUsersQuery = {
  syncUsers?:  {
    __typename: "ModelUserConnection",
    items?:  Array< {
      __typename: "User",
      id: string,
      sub?: string | null,
      name?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      enabled?: boolean | null,
      status: Status,
      roles: Array< Role >,
      lastActive?: string | null,
      deletedAt?: string | null,
      enterpriseID?: string | null,
      phoneNumber?: string | null,
      designation?: string | null,
      optin?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      groupMemberships?:  {
        __typename: "ModelGroupMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetGroupQueryVariables = {
  id: string,
};

export type GetGroupQuery = {
  getGroup?:  {
    __typename: "Group",
    id: string,
    name: string,
    maxMembers?: number | null,
    deletedAt?: string | null,
    enterpriseID?: string | null,
    membersCount?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    members?:  {
      __typename: "ModelGroupMemberConnection",
      items?:  Array< {
        __typename: "GroupMember",
        id: string,
        memberID: string,
        groupID: string,
        isManager?: boolean | null,
        isLearner?: boolean | null,
        userSub?: string | null,
        deletedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type ListGroupsQueryVariables = {
  filter?: ModelGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListGroupsQuery = {
  listGroups?:  {
    __typename: "ModelGroupConnection",
    items?:  Array< {
      __typename: "Group",
      id: string,
      name: string,
      maxMembers?: number | null,
      deletedAt?: string | null,
      enterpriseID?: string | null,
      membersCount?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      members?:  {
        __typename: "ModelGroupMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncGroupsQueryVariables = {
  filter?: ModelGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncGroupsQuery = {
  syncGroups?:  {
    __typename: "ModelGroupConnection",
    items?:  Array< {
      __typename: "Group",
      id: string,
      name: string,
      maxMembers?: number | null,
      deletedAt?: string | null,
      enterpriseID?: string | null,
      membersCount?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      members?:  {
        __typename: "ModelGroupMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetGroupMemberQueryVariables = {
  id: string,
};

export type GetGroupMemberQuery = {
  getGroupMember?:  {
    __typename: "GroupMember",
    id: string,
    memberID: string,
    groupID: string,
    isManager?: boolean | null,
    isLearner?: boolean | null,
    userSub?: string | null,
    deletedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    member:  {
      __typename: "User",
      id: string,
      sub?: string | null,
      name?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      enabled?: boolean | null,
      status: Status,
      roles: Array< Role >,
      lastActive?: string | null,
      deletedAt?: string | null,
      enterpriseID?: string | null,
      phoneNumber?: string | null,
      designation?: string | null,
      optin?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      groupMemberships?:  {
        __typename: "ModelGroupMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    },
    group:  {
      __typename: "Group",
      id: string,
      name: string,
      maxMembers?: number | null,
      deletedAt?: string | null,
      enterpriseID?: string | null,
      membersCount?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      members?:  {
        __typename: "ModelGroupMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    },
  } | null,
};

export type AdminListGroupMembersQueryVariables = {
  filter?: ModelGroupMemberFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AdminListGroupMembersQuery = {
  adminListGroupMembers?:  {
    __typename: "ModelGroupMemberConnection",
    items?:  Array< {
      __typename: "GroupMember",
      id: string,
      memberID: string,
      groupID: string,
      isManager?: boolean | null,
      isLearner?: boolean | null,
      userSub?: string | null,
      deletedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      member:  {
        __typename: "User",
        id: string,
        sub?: string | null,
        name?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email: string,
        enabled?: boolean | null,
        status: Status,
        roles: Array< Role >,
        lastActive?: string | null,
        deletedAt?: string | null,
        enterpriseID?: string | null,
        phoneNumber?: string | null,
        designation?: string | null,
        optin?: boolean | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      },
      group:  {
        __typename: "Group",
        id: string,
        name: string,
        maxMembers?: number | null,
        deletedAt?: string | null,
        enterpriseID?: string | null,
        membersCount?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      },
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListGroupMembersByUserSubQueryVariables = {
  userSub?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelGroupMemberFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListGroupMembersByUserSubQuery = {
  listGroupMembersByUserSub?:  {
    __typename: "ModelGroupMemberConnection",
    items?:  Array< {
      __typename: "GroupMember",
      id: string,
      memberID: string,
      groupID: string,
      isManager?: boolean | null,
      isLearner?: boolean | null,
      userSub?: string | null,
      deletedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      member:  {
        __typename: "User",
        id: string,
        sub?: string | null,
        name?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email: string,
        enabled?: boolean | null,
        status: Status,
        roles: Array< Role >,
        lastActive?: string | null,
        deletedAt?: string | null,
        enterpriseID?: string | null,
        phoneNumber?: string | null,
        designation?: string | null,
        optin?: boolean | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      },
      group:  {
        __typename: "Group",
        id: string,
        name: string,
        maxMembers?: number | null,
        deletedAt?: string | null,
        enterpriseID?: string | null,
        membersCount?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      },
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncGroupMembersQueryVariables = {
  filter?: ModelGroupMemberFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncGroupMembersQuery = {
  syncGroupMembers?:  {
    __typename: "ModelGroupMemberConnection",
    items?:  Array< {
      __typename: "GroupMember",
      id: string,
      memberID: string,
      groupID: string,
      isManager?: boolean | null,
      isLearner?: boolean | null,
      userSub?: string | null,
      deletedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      member:  {
        __typename: "User",
        id: string,
        sub?: string | null,
        name?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email: string,
        enabled?: boolean | null,
        status: Status,
        roles: Array< Role >,
        lastActive?: string | null,
        deletedAt?: string | null,
        enterpriseID?: string | null,
        phoneNumber?: string | null,
        designation?: string | null,
        optin?: boolean | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      },
      group:  {
        __typename: "Group",
        id: string,
        name: string,
        maxMembers?: number | null,
        deletedAt?: string | null,
        enterpriseID?: string | null,
        membersCount?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      },
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetEnterpriseProfileQueryVariables = {
  id: string,
};

export type GetEnterpriseProfileQuery = {
  getEnterpriseProfile?:  {
    __typename: "EnterpriseProfile",
    id: string,
    name: string,
    licenseCount: number,
    membership?:  {
      __typename: "EnterpriseMembership",
      name: string,
      status: EnterpriseMembershipStatus,
      createdAt: string,
      updatedAt: string,
    } | null,
    subscription?:  {
      __typename: "EnterpriseSubscription",
      id: string,
      provider: SubscriptionProvider,
      providerSubID: string,
      providerCustomerID: string,
      status: EnterpriseSubscriptionStatus,
      entitlements:  Array< {
        __typename: "SubscriptionEntitlement",
        featureSlug: FeatureSlug,
        featureType: FeatureType,
        featureUnit?: string | null,
        value?: string | null,
      } | null >,
      currencyCode?: string | null,
      trialStart?: string | null,
      trialEnd?: string | null,
      currentTermStart?: string | null,
      currentTermEnd?: string | null,
      nextBillingAt?: string | null,
      cancelledAt?: string | null,
      items:  Array< {
        __typename: "SubscriptionItem",
        itemPriceId: string,
        itemType: SubscriptionItemType,
        quantity?: number | null,
        unitPrice?: string | null,
        amount?: string | null,
        freeQuantity?: number | null,
        name?: string | null,
      } | null >,
      billingPeriod?: number | null,
      billingPeriodUnit?: BillingPeriodUnit | null,
      resourceVersion?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    courseContentVersion?: number | null,
    deletedAt?: string | null,
    numberOfEmployees?: string | null,
    location?: string | null,
    hiddenCourses?: Array< string > | null,
    scormCloudAppID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListEnterpriseProfilesQueryVariables = {
  filter?: ModelEnterpriseProfileFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEnterpriseProfilesQuery = {
  listEnterpriseProfiles?:  {
    __typename: "ModelEnterpriseProfileConnection",
    items?:  Array< {
      __typename: "EnterpriseProfile",
      id: string,
      name: string,
      licenseCount: number,
      membership?:  {
        __typename: "EnterpriseMembership",
        name: string,
        status: EnterpriseMembershipStatus,
        createdAt: string,
        updatedAt: string,
      } | null,
      subscription?:  {
        __typename: "EnterpriseSubscription",
        id: string,
        provider: SubscriptionProvider,
        providerSubID: string,
        providerCustomerID: string,
        status: EnterpriseSubscriptionStatus,
        currencyCode?: string | null,
        trialStart?: string | null,
        trialEnd?: string | null,
        currentTermStart?: string | null,
        currentTermEnd?: string | null,
        nextBillingAt?: string | null,
        cancelledAt?: string | null,
        billingPeriod?: number | null,
        billingPeriodUnit?: BillingPeriodUnit | null,
        resourceVersion?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      courseContentVersion?: number | null,
      deletedAt?: string | null,
      numberOfEmployees?: string | null,
      location?: string | null,
      hiddenCourses?: Array< string > | null,
      scormCloudAppID?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncEnterpriseProfilesQueryVariables = {
  filter?: ModelEnterpriseProfileFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncEnterpriseProfilesQuery = {
  syncEnterpriseProfiles?:  {
    __typename: "ModelEnterpriseProfileConnection",
    items?:  Array< {
      __typename: "EnterpriseProfile",
      id: string,
      name: string,
      licenseCount: number,
      membership?:  {
        __typename: "EnterpriseMembership",
        name: string,
        status: EnterpriseMembershipStatus,
        createdAt: string,
        updatedAt: string,
      } | null,
      subscription?:  {
        __typename: "EnterpriseSubscription",
        id: string,
        provider: SubscriptionProvider,
        providerSubID: string,
        providerCustomerID: string,
        status: EnterpriseSubscriptionStatus,
        currencyCode?: string | null,
        trialStart?: string | null,
        trialEnd?: string | null,
        currentTermStart?: string | null,
        currentTermEnd?: string | null,
        nextBillingAt?: string | null,
        cancelledAt?: string | null,
        billingPeriod?: number | null,
        billingPeriodUnit?: BillingPeriodUnit | null,
        resourceVersion?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      courseContentVersion?: number | null,
      deletedAt?: string | null,
      numberOfEmployees?: string | null,
      location?: string | null,
      hiddenCourses?: Array< string > | null,
      scormCloudAppID?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetCourseQueryVariables = {
  id: string,
};

export type GetCourseQuery = {
  getCourse?:  {
    __typename: "Course",
    id: string,
    source: string,
    sourceID: string,
    locale: string,
    courseName: string,
    courseDescription?: string | null,
    starterPack?:  {
      __typename: "ResourceCollection",
      title: string,
      slug: string,
      items:  Array< {
        __typename: "Resource",
        resourceType: ResourceType,
      } >,
    } | null,
    active?: boolean | null,
    modules:  Array< {
      __typename: "CourseModule",
      id: string,
      source: string,
      sourceID: string,
      locale: string,
      moduleName: string,
      moduleDescription?: string | null,
      tasks:  Array< {
        __typename: "Task",
        id: string,
        taskType: TaskType,
      } >,
      createdAt: string,
      updatedAt: string,
    } >,
    tags: Array< string | null >,
    categories: Array< string | null >,
    courseTileImage?:  {
      __typename: "Link",
      title: string,
      href: string,
    } | null,
    courseHeroBannerImage?:  {
      __typename: "Link",
      title: string,
      href: string,
    } | null,
    deletedAt?: string | null,
    type?: CourseType | null,
    enterpriseID?: string | null,
    learningStandard?: CourseLearningStandard | null,
    accessType?: CourseAccessType | null,
    status?: CourseStatus | null,
    uploadType?: CourseUploadType | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCoursesQueryVariables = {
  filter?: ModelCourseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCoursesQuery = {
  listCourses?:  {
    __typename: "ModelCourseConnection",
    items?:  Array< {
      __typename: "Course",
      id: string,
      source: string,
      sourceID: string,
      locale: string,
      courseName: string,
      courseDescription?: string | null,
      starterPack?:  {
        __typename: "ResourceCollection",
        title: string,
        slug: string,
      } | null,
      active?: boolean | null,
      modules:  Array< {
        __typename: "CourseModule",
        id: string,
        source: string,
        sourceID: string,
        locale: string,
        moduleName: string,
        moduleDescription?: string | null,
        createdAt: string,
        updatedAt: string,
      } >,
      tags: Array< string | null >,
      categories: Array< string | null >,
      courseTileImage?:  {
        __typename: "Link",
        title: string,
        href: string,
      } | null,
      courseHeroBannerImage?:  {
        __typename: "Link",
        title: string,
        href: string,
      } | null,
      deletedAt?: string | null,
      type?: CourseType | null,
      enterpriseID?: string | null,
      learningStandard?: CourseLearningStandard | null,
      accessType?: CourseAccessType | null,
      status?: CourseStatus | null,
      uploadType?: CourseUploadType | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncCoursesQueryVariables = {
  filter?: ModelCourseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncCoursesQuery = {
  syncCourses?:  {
    __typename: "ModelCourseConnection",
    items?:  Array< {
      __typename: "Course",
      id: string,
      source: string,
      sourceID: string,
      locale: string,
      courseName: string,
      courseDescription?: string | null,
      starterPack?:  {
        __typename: "ResourceCollection",
        title: string,
        slug: string,
      } | null,
      active?: boolean | null,
      modules:  Array< {
        __typename: "CourseModule",
        id: string,
        source: string,
        sourceID: string,
        locale: string,
        moduleName: string,
        moduleDescription?: string | null,
        createdAt: string,
        updatedAt: string,
      } >,
      tags: Array< string | null >,
      categories: Array< string | null >,
      courseTileImage?:  {
        __typename: "Link",
        title: string,
        href: string,
      } | null,
      courseHeroBannerImage?:  {
        __typename: "Link",
        title: string,
        href: string,
      } | null,
      deletedAt?: string | null,
      type?: CourseType | null,
      enterpriseID?: string | null,
      learningStandard?: CourseLearningStandard | null,
      accessType?: CourseAccessType | null,
      status?: CourseStatus | null,
      uploadType?: CourseUploadType | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetSourceQueryVariables = {
  id: string,
};

export type GetSourceQuery = {
  getSource?:  {
    __typename: "Source",
    id: string,
    name: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListSourcesQueryVariables = {
  filter?: ModelSourceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSourcesQuery = {
  listSources?:  {
    __typename: "ModelSourceConnection",
    items?:  Array< {
      __typename: "Source",
      id: string,
      name: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncSourcesQueryVariables = {
  filter?: ModelSourceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncSourcesQuery = {
  syncSources?:  {
    __typename: "ModelSourceConnection",
    items?:  Array< {
      __typename: "Source",
      id: string,
      name: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetReportQueryVariables = {
  id: string,
};

export type GetReportQuery = {
  getReport?:  {
    __typename: "Report",
    id: string,
    sourceReferenceID: string,
    sourceID: string,
    locale: string,
    name: string,
    heading?: string | null,
    subHeading?: string | null,
    startCTA?: string | null,
    explainer?: string | null,
    assessment:  {
      __typename: "AssignmentTask",
      source: AssignmentSource,
      title: string,
      formId?: string | null,
    },
    skills:  Array< {
      __typename: "Skill",
      id: string,
      label: string,
      skillReport?:  {
        __typename: "Link",
        title: string,
        href: string,
      } | null,
      iconLink?:  {
        __typename: "Link",
        title: string,
        href: string,
      } | null,
      selectedIconLink?:  {
        __typename: "Link",
        title: string,
        href: string,
      } | null,
      subSkills:  Array< {
        __typename: "SubSkill",
        id: string,
        label: string,
        description?: string | null,
      } >,
    } >,
    deletedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    source?:  {
      __typename: "Source",
      id: string,
      name: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type ListReportsQueryVariables = {
  filter?: ModelReportFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReportsQuery = {
  listReports?:  {
    __typename: "ModelReportConnection",
    items?:  Array< {
      __typename: "Report",
      id: string,
      sourceReferenceID: string,
      sourceID: string,
      locale: string,
      name: string,
      heading?: string | null,
      subHeading?: string | null,
      startCTA?: string | null,
      explainer?: string | null,
      assessment:  {
        __typename: "AssignmentTask",
        source: AssignmentSource,
        title: string,
        formId?: string | null,
      },
      skills:  Array< {
        __typename: "Skill",
        id: string,
        label: string,
      } >,
      deletedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      source?:  {
        __typename: "Source",
        id: string,
        name: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncReportsQueryVariables = {
  filter?: ModelReportFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncReportsQuery = {
  syncReports?:  {
    __typename: "ModelReportConnection",
    items?:  Array< {
      __typename: "Report",
      id: string,
      sourceReferenceID: string,
      sourceID: string,
      locale: string,
      name: string,
      heading?: string | null,
      subHeading?: string | null,
      startCTA?: string | null,
      explainer?: string | null,
      assessment:  {
        __typename: "AssignmentTask",
        source: AssignmentSource,
        title: string,
        formId?: string | null,
      },
      skills:  Array< {
        __typename: "Skill",
        id: string,
        label: string,
      } >,
      deletedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      source?:  {
        __typename: "Source",
        id: string,
        name: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetReportResultQueryVariables = {
  id: string,
};

export type GetReportResultQuery = {
  getReportResult?:  {
    __typename: "ReportResult",
    id: string,
    reportID: string,
    userID: string,
    userSub: string,
    formID: string,
    responseID: string,
    skillScores:  Array< {
      __typename: "SkillScore",
      skillID: string,
      score: number,
      maxScore: number,
      subSkillScores:  Array< {
        __typename: "SubSkillScore",
        subSkillID: string,
        score: number,
        maxScore: number,
        scoreKey: string,
      } >,
    } >,
    deletedAt?: string | null,
    enterpriseID: string,
    archived?: boolean | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    user?:  {
      __typename: "User",
      id: string,
      sub?: string | null,
      name?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      enabled?: boolean | null,
      status: Status,
      roles: Array< Role >,
      lastActive?: string | null,
      deletedAt?: string | null,
      enterpriseID?: string | null,
      phoneNumber?: string | null,
      designation?: string | null,
      optin?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      groupMemberships?:  {
        __typename: "ModelGroupMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
    report?:  {
      __typename: "Report",
      id: string,
      sourceReferenceID: string,
      sourceID: string,
      locale: string,
      name: string,
      heading?: string | null,
      subHeading?: string | null,
      startCTA?: string | null,
      explainer?: string | null,
      assessment:  {
        __typename: "AssignmentTask",
        source: AssignmentSource,
        title: string,
        formId?: string | null,
      },
      skills:  Array< {
        __typename: "Skill",
        id: string,
        label: string,
      } >,
      deletedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      source?:  {
        __typename: "Source",
        id: string,
        name: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
  } | null,
};

export type ListReportResultsQueryVariables = {
  filter?: ModelReportResultFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReportResultsQuery = {
  listReportResults?:  {
    __typename: "ModelReportResultConnection",
    items?:  Array< {
      __typename: "ReportResult",
      id: string,
      reportID: string,
      userID: string,
      userSub: string,
      formID: string,
      responseID: string,
      skillScores:  Array< {
        __typename: "SkillScore",
        skillID: string,
        score: number,
        maxScore: number,
      } >,
      deletedAt?: string | null,
      enterpriseID: string,
      archived?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      user?:  {
        __typename: "User",
        id: string,
        sub?: string | null,
        name?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email: string,
        enabled?: boolean | null,
        status: Status,
        roles: Array< Role >,
        lastActive?: string | null,
        deletedAt?: string | null,
        enterpriseID?: string | null,
        phoneNumber?: string | null,
        designation?: string | null,
        optin?: boolean | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null,
      report?:  {
        __typename: "Report",
        id: string,
        sourceReferenceID: string,
        sourceID: string,
        locale: string,
        name: string,
        heading?: string | null,
        subHeading?: string | null,
        startCTA?: string | null,
        explainer?: string | null,
        deletedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListReportResultsByUserSubQueryVariables = {
  userSub?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReportResultFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReportResultsByUserSubQuery = {
  listReportResultsByUserSub?:  {
    __typename: "ModelReportResultConnection",
    items?:  Array< {
      __typename: "ReportResult",
      id: string,
      reportID: string,
      userID: string,
      userSub: string,
      formID: string,
      responseID: string,
      skillScores:  Array< {
        __typename: "SkillScore",
        skillID: string,
        score: number,
        maxScore: number,
      } >,
      deletedAt?: string | null,
      enterpriseID: string,
      archived?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      user?:  {
        __typename: "User",
        id: string,
        sub?: string | null,
        name?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email: string,
        enabled?: boolean | null,
        status: Status,
        roles: Array< Role >,
        lastActive?: string | null,
        deletedAt?: string | null,
        enterpriseID?: string | null,
        phoneNumber?: string | null,
        designation?: string | null,
        optin?: boolean | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null,
      report?:  {
        __typename: "Report",
        id: string,
        sourceReferenceID: string,
        sourceID: string,
        locale: string,
        name: string,
        heading?: string | null,
        subHeading?: string | null,
        startCTA?: string | null,
        explainer?: string | null,
        deletedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListReportResultsByEnterpriseIDQueryVariables = {
  enterpriseID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReportResultFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReportResultsByEnterpriseIDQuery = {
  listReportResultsByEnterpriseID?:  {
    __typename: "ModelReportResultConnection",
    items?:  Array< {
      __typename: "ReportResult",
      id: string,
      reportID: string,
      userID: string,
      userSub: string,
      formID: string,
      responseID: string,
      skillScores:  Array< {
        __typename: "SkillScore",
        skillID: string,
        score: number,
        maxScore: number,
      } >,
      deletedAt?: string | null,
      enterpriseID: string,
      archived?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      user?:  {
        __typename: "User",
        id: string,
        sub?: string | null,
        name?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email: string,
        enabled?: boolean | null,
        status: Status,
        roles: Array< Role >,
        lastActive?: string | null,
        deletedAt?: string | null,
        enterpriseID?: string | null,
        phoneNumber?: string | null,
        designation?: string | null,
        optin?: boolean | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null,
      report?:  {
        __typename: "Report",
        id: string,
        sourceReferenceID: string,
        sourceID: string,
        locale: string,
        name: string,
        heading?: string | null,
        subHeading?: string | null,
        startCTA?: string | null,
        explainer?: string | null,
        deletedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncReportResultsQueryVariables = {
  filter?: ModelReportResultFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncReportResultsQuery = {
  syncReportResults?:  {
    __typename: "ModelReportResultConnection",
    items?:  Array< {
      __typename: "ReportResult",
      id: string,
      reportID: string,
      userID: string,
      userSub: string,
      formID: string,
      responseID: string,
      skillScores:  Array< {
        __typename: "SkillScore",
        skillID: string,
        score: number,
        maxScore: number,
      } >,
      deletedAt?: string | null,
      enterpriseID: string,
      archived?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      user?:  {
        __typename: "User",
        id: string,
        sub?: string | null,
        name?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email: string,
        enabled?: boolean | null,
        status: Status,
        roles: Array< Role >,
        lastActive?: string | null,
        deletedAt?: string | null,
        enterpriseID?: string | null,
        phoneNumber?: string | null,
        designation?: string | null,
        optin?: boolean | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null,
      report?:  {
        __typename: "Report",
        id: string,
        sourceReferenceID: string,
        sourceID: string,
        locale: string,
        name: string,
        heading?: string | null,
        subHeading?: string | null,
        startCTA?: string | null,
        explainer?: string | null,
        deletedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetReportSubmissionQueryVariables = {
  id: string,
};

export type GetReportSubmissionQuery = {
  getReportSubmission?:  {
    __typename: "ReportSubmission",
    id: string,
    reportID: string,
    userID: string,
    userSub: string,
    status: ReportSubmissionStatus,
    deletedAt?: string | null,
    enterpriseID: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListReportSubmissionsQueryVariables = {
  filter?: ModelReportSubmissionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReportSubmissionsQuery = {
  listReportSubmissions?:  {
    __typename: "ModelReportSubmissionConnection",
    items?:  Array< {
      __typename: "ReportSubmission",
      id: string,
      reportID: string,
      userID: string,
      userSub: string,
      status: ReportSubmissionStatus,
      deletedAt?: string | null,
      enterpriseID: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListReportSubmissionsByUserSubQueryVariables = {
  userSub?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReportSubmissionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReportSubmissionsByUserSubQuery = {
  listReportSubmissionsByUserSub?:  {
    __typename: "ModelReportSubmissionConnection",
    items?:  Array< {
      __typename: "ReportSubmission",
      id: string,
      reportID: string,
      userID: string,
      userSub: string,
      status: ReportSubmissionStatus,
      deletedAt?: string | null,
      enterpriseID: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListReportSubmissionsByEnterpriseIDQueryVariables = {
  enterpriseID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReportSubmissionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReportSubmissionsByEnterpriseIDQuery = {
  listReportSubmissionsByEnterpriseID?:  {
    __typename: "ModelReportSubmissionConnection",
    items?:  Array< {
      __typename: "ReportSubmission",
      id: string,
      reportID: string,
      userID: string,
      userSub: string,
      status: ReportSubmissionStatus,
      deletedAt?: string | null,
      enterpriseID: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncReportSubmissionsQueryVariables = {
  filter?: ModelReportSubmissionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncReportSubmissionsQuery = {
  syncReportSubmissions?:  {
    __typename: "ModelReportSubmissionConnection",
    items?:  Array< {
      __typename: "ReportSubmission",
      id: string,
      reportID: string,
      userID: string,
      userSub: string,
      status: ReportSubmissionStatus,
      deletedAt?: string | null,
      enterpriseID: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetTypeformResponseQueryVariables = {
  id: string,
};

export type GetTypeformResponseQuery = {
  getTypeformResponse?:  {
    __typename: "TypeformResponse",
    id: string,
    formID: string,
    userID?: string | null,
    assessmentType?: string | null,
    payload: string,
    deletedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListTypeformResponsesQueryVariables = {
  filter?: ModelTypeformResponseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTypeformResponsesQuery = {
  listTypeformResponses?:  {
    __typename: "ModelTypeformResponseConnection",
    items?:  Array< {
      __typename: "TypeformResponse",
      id: string,
      formID: string,
      userID?: string | null,
      assessmentType?: string | null,
      payload: string,
      deletedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncTypeformResponsesQueryVariables = {
  filter?: ModelTypeformResponseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncTypeformResponsesQuery = {
  syncTypeformResponses?:  {
    __typename: "ModelTypeformResponseConnection",
    items?:  Array< {
      __typename: "TypeformResponse",
      id: string,
      formID: string,
      userID?: string | null,
      assessmentType?: string | null,
      payload: string,
      deletedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetCourseRegistrationQueryVariables = {
  id: string,
};

export type GetCourseRegistrationQuery = {
  getCourseRegistration?:  {
    __typename: "CourseRegistration",
    id: string,
    courseID: string,
    userID: string,
    userSub?: string | null,
    status: CourseRegistrationStatus,
    deadline?: string | null,
    deletedAt?: string | null,
    enterpriseID?: string | null,
    assignedByUserID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCourseRegistrationsQueryVariables = {
  filter?: ModelCourseRegistrationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCourseRegistrationsQuery = {
  listCourseRegistrations?:  {
    __typename: "ModelCourseRegistrationConnection",
    items?:  Array< {
      __typename: "CourseRegistration",
      id: string,
      courseID: string,
      userID: string,
      userSub?: string | null,
      status: CourseRegistrationStatus,
      deadline?: string | null,
      deletedAt?: string | null,
      enterpriseID?: string | null,
      assignedByUserID?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListCourseRegistrationsByUserSubQueryVariables = {
  userSub?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCourseRegistrationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCourseRegistrationsByUserSubQuery = {
  listCourseRegistrationsByUserSub?:  {
    __typename: "ModelCourseRegistrationConnection",
    items?:  Array< {
      __typename: "CourseRegistration",
      id: string,
      courseID: string,
      userID: string,
      userSub?: string | null,
      status: CourseRegistrationStatus,
      deadline?: string | null,
      deletedAt?: string | null,
      enterpriseID?: string | null,
      assignedByUserID?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListCourseRegistrationsByEnterpriseIDQueryVariables = {
  enterpriseID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCourseRegistrationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCourseRegistrationsByEnterpriseIDQuery = {
  listCourseRegistrationsByEnterpriseID?:  {
    __typename: "ModelCourseRegistrationConnection",
    items?:  Array< {
      __typename: "CourseRegistration",
      id: string,
      courseID: string,
      userID: string,
      userSub?: string | null,
      status: CourseRegistrationStatus,
      deadline?: string | null,
      deletedAt?: string | null,
      enterpriseID?: string | null,
      assignedByUserID?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncCourseRegistrationsQueryVariables = {
  filter?: ModelCourseRegistrationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncCourseRegistrationsQuery = {
  syncCourseRegistrations?:  {
    __typename: "ModelCourseRegistrationConnection",
    items?:  Array< {
      __typename: "CourseRegistration",
      id: string,
      courseID: string,
      userID: string,
      userSub?: string | null,
      status: CourseRegistrationStatus,
      deadline?: string | null,
      deletedAt?: string | null,
      enterpriseID?: string | null,
      assignedByUserID?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetCourseRegistrationActivityQueryVariables = {
  id: string,
};

export type GetCourseRegistrationActivityQuery = {
  getCourseRegistrationActivity?:  {
    __typename: "CourseRegistrationActivity",
    id: string,
    courseRegistrationID: string,
    userID: string,
    userSub?: string | null,
    taskID: string,
    taskType: TaskType,
    courseID: string,
    status: CourseRegistrationActivityStatus,
    deletedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    user?:  {
      __typename: "User",
      id: string,
      sub?: string | null,
      name?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      enabled?: boolean | null,
      status: Status,
      roles: Array< Role >,
      lastActive?: string | null,
      deletedAt?: string | null,
      enterpriseID?: string | null,
      phoneNumber?: string | null,
      designation?: string | null,
      optin?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      groupMemberships?:  {
        __typename: "ModelGroupMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
    courseRegistration?:  {
      __typename: "CourseRegistration",
      id: string,
      courseID: string,
      userID: string,
      userSub?: string | null,
      status: CourseRegistrationStatus,
      deadline?: string | null,
      deletedAt?: string | null,
      enterpriseID?: string | null,
      assignedByUserID?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type ListCourseRegistrationActivitiesQueryVariables = {
  filter?: ModelCourseRegistrationActivityFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCourseRegistrationActivitiesQuery = {
  listCourseRegistrationActivities?:  {
    __typename: "ModelCourseRegistrationActivityConnection",
    items?:  Array< {
      __typename: "CourseRegistrationActivity",
      id: string,
      courseRegistrationID: string,
      userID: string,
      userSub?: string | null,
      taskID: string,
      taskType: TaskType,
      courseID: string,
      status: CourseRegistrationActivityStatus,
      deletedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      user?:  {
        __typename: "User",
        id: string,
        sub?: string | null,
        name?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email: string,
        enabled?: boolean | null,
        status: Status,
        roles: Array< Role >,
        lastActive?: string | null,
        deletedAt?: string | null,
        enterpriseID?: string | null,
        phoneNumber?: string | null,
        designation?: string | null,
        optin?: boolean | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null,
      courseRegistration?:  {
        __typename: "CourseRegistration",
        id: string,
        courseID: string,
        userID: string,
        userSub?: string | null,
        status: CourseRegistrationStatus,
        deadline?: string | null,
        deletedAt?: string | null,
        enterpriseID?: string | null,
        assignedByUserID?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListCourseRegistrationActivitiesByUserSubQueryVariables = {
  userSub?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCourseRegistrationActivityFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCourseRegistrationActivitiesByUserSubQuery = {
  listCourseRegistrationActivitiesByUserSub?:  {
    __typename: "ModelCourseRegistrationActivityConnection",
    items?:  Array< {
      __typename: "CourseRegistrationActivity",
      id: string,
      courseRegistrationID: string,
      userID: string,
      userSub?: string | null,
      taskID: string,
      taskType: TaskType,
      courseID: string,
      status: CourseRegistrationActivityStatus,
      deletedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      user?:  {
        __typename: "User",
        id: string,
        sub?: string | null,
        name?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email: string,
        enabled?: boolean | null,
        status: Status,
        roles: Array< Role >,
        lastActive?: string | null,
        deletedAt?: string | null,
        enterpriseID?: string | null,
        phoneNumber?: string | null,
        designation?: string | null,
        optin?: boolean | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null,
      courseRegistration?:  {
        __typename: "CourseRegistration",
        id: string,
        courseID: string,
        userID: string,
        userSub?: string | null,
        status: CourseRegistrationStatus,
        deadline?: string | null,
        deletedAt?: string | null,
        enterpriseID?: string | null,
        assignedByUserID?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncCourseRegistrationActivitiesQueryVariables = {
  filter?: ModelCourseRegistrationActivityFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncCourseRegistrationActivitiesQuery = {
  syncCourseRegistrationActivities?:  {
    __typename: "ModelCourseRegistrationActivityConnection",
    items?:  Array< {
      __typename: "CourseRegistrationActivity",
      id: string,
      courseRegistrationID: string,
      userID: string,
      userSub?: string | null,
      taskID: string,
      taskType: TaskType,
      courseID: string,
      status: CourseRegistrationActivityStatus,
      deletedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      user?:  {
        __typename: "User",
        id: string,
        sub?: string | null,
        name?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email: string,
        enabled?: boolean | null,
        status: Status,
        roles: Array< Role >,
        lastActive?: string | null,
        deletedAt?: string | null,
        enterpriseID?: string | null,
        phoneNumber?: string | null,
        designation?: string | null,
        optin?: boolean | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null,
      courseRegistration?:  {
        __typename: "CourseRegistration",
        id: string,
        courseID: string,
        userID: string,
        userSub?: string | null,
        status: CourseRegistrationStatus,
        deadline?: string | null,
        deletedAt?: string | null,
        enterpriseID?: string | null,
        assignedByUserID?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetAssignmentResponseQueryVariables = {
  id: string,
};

export type GetAssignmentResponseQuery = {
  getAssignmentResponse?:  {
    __typename: "AssignmentResponse",
    id: string,
    courseRegistrationID: string,
    userID: string,
    userSub: string,
    taskID: string,
    source: AssignmentSource,
    formID: string,
    responseID: string,
    score: number,
    totalScore: number,
    deletedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListAssignmentResponsesQueryVariables = {
  filter?: ModelAssignmentResponseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAssignmentResponsesQuery = {
  listAssignmentResponses?:  {
    __typename: "ModelAssignmentResponseConnection",
    items?:  Array< {
      __typename: "AssignmentResponse",
      id: string,
      courseRegistrationID: string,
      userID: string,
      userSub: string,
      taskID: string,
      source: AssignmentSource,
      formID: string,
      responseID: string,
      score: number,
      totalScore: number,
      deletedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListAssignmentResponsesByUserSubQueryVariables = {
  userSub?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAssignmentResponseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAssignmentResponsesByUserSubQuery = {
  listAssignmentResponsesByUserSub?:  {
    __typename: "ModelAssignmentResponseConnection",
    items?:  Array< {
      __typename: "AssignmentResponse",
      id: string,
      courseRegistrationID: string,
      userID: string,
      userSub: string,
      taskID: string,
      source: AssignmentSource,
      formID: string,
      responseID: string,
      score: number,
      totalScore: number,
      deletedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncAssignmentResponsesQueryVariables = {
  filter?: ModelAssignmentResponseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncAssignmentResponsesQuery = {
  syncAssignmentResponses?:  {
    __typename: "ModelAssignmentResponseConnection",
    items?:  Array< {
      __typename: "AssignmentResponse",
      id: string,
      courseRegistrationID: string,
      userID: string,
      userSub: string,
      taskID: string,
      source: AssignmentSource,
      formID: string,
      responseID: string,
      score: number,
      totalScore: number,
      deletedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetNotificationQueryVariables = {
  id: string,
};

export type GetNotificationQuery = {
  getNotification?:  {
    __typename: "Notification",
    id: string,
    userID: string,
    userSub: string,
    title: string,
    message?: string | null,
    read?: boolean | null,
    deletedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    user?:  {
      __typename: "User",
      id: string,
      sub?: string | null,
      name?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      enabled?: boolean | null,
      status: Status,
      roles: Array< Role >,
      lastActive?: string | null,
      deletedAt?: string | null,
      enterpriseID?: string | null,
      phoneNumber?: string | null,
      designation?: string | null,
      optin?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      groupMemberships?:  {
        __typename: "ModelGroupMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
  } | null,
};

export type ListNotificationsQueryVariables = {
  filter?: ModelNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNotificationsQuery = {
  listNotifications?:  {
    __typename: "ModelNotificationConnection",
    items?:  Array< {
      __typename: "Notification",
      id: string,
      userID: string,
      userSub: string,
      title: string,
      message?: string | null,
      read?: boolean | null,
      deletedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      user?:  {
        __typename: "User",
        id: string,
        sub?: string | null,
        name?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email: string,
        enabled?: boolean | null,
        status: Status,
        roles: Array< Role >,
        lastActive?: string | null,
        deletedAt?: string | null,
        enterpriseID?: string | null,
        phoneNumber?: string | null,
        designation?: string | null,
        optin?: boolean | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListNotificationsByUserSubQueryVariables = {
  userSub?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNotificationsByUserSubQuery = {
  listNotificationsByUserSub?:  {
    __typename: "ModelNotificationConnection",
    items?:  Array< {
      __typename: "Notification",
      id: string,
      userID: string,
      userSub: string,
      title: string,
      message?: string | null,
      read?: boolean | null,
      deletedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      user?:  {
        __typename: "User",
        id: string,
        sub?: string | null,
        name?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email: string,
        enabled?: boolean | null,
        status: Status,
        roles: Array< Role >,
        lastActive?: string | null,
        deletedAt?: string | null,
        enterpriseID?: string | null,
        phoneNumber?: string | null,
        designation?: string | null,
        optin?: boolean | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncNotificationsQueryVariables = {
  filter?: ModelNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncNotificationsQuery = {
  syncNotifications?:  {
    __typename: "ModelNotificationConnection",
    items?:  Array< {
      __typename: "Notification",
      id: string,
      userID: string,
      userSub: string,
      title: string,
      message?: string | null,
      read?: boolean | null,
      deletedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      user?:  {
        __typename: "User",
        id: string,
        sub?: string | null,
        name?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email: string,
        enabled?: boolean | null,
        status: Status,
        roles: Array< Role >,
        lastActive?: string | null,
        deletedAt?: string | null,
        enterpriseID?: string | null,
        phoneNumber?: string | null,
        designation?: string | null,
        optin?: boolean | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetInvitationQueryVariables = {
  email: string,
  inviterEnterpriseID: string,
};

export type GetInvitationQuery = {
  getInvitation?:  {
    __typename: "Invitation",
    id: string,
    email: string,
    status: InvitationStatus,
    inviterID: string,
    inviterEnterpriseID: string,
    inviteeID?: string | null,
    roles?: Array< Role | null > | null,
    groupID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    inviter?:  {
      __typename: "User",
      id: string,
      sub?: string | null,
      name?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      enabled?: boolean | null,
      status: Status,
      roles: Array< Role >,
      lastActive?: string | null,
      deletedAt?: string | null,
      enterpriseID?: string | null,
      phoneNumber?: string | null,
      designation?: string | null,
      optin?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      groupMemberships?:  {
        __typename: "ModelGroupMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
  } | null,
};

export type ListInvitationsQueryVariables = {
  email?: string | null,
  inviterEnterpriseID?: ModelIDKeyConditionInput | null,
  filter?: ModelInvitationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListInvitationsQuery = {
  listInvitations?:  {
    __typename: "ModelInvitationConnection",
    items?:  Array< {
      __typename: "Invitation",
      id: string,
      email: string,
      status: InvitationStatus,
      inviterID: string,
      inviterEnterpriseID: string,
      inviteeID?: string | null,
      roles?: Array< Role | null > | null,
      groupID?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      inviter?:  {
        __typename: "User",
        id: string,
        sub?: string | null,
        name?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email: string,
        enabled?: boolean | null,
        status: Status,
        roles: Array< Role >,
        lastActive?: string | null,
        deletedAt?: string | null,
        enterpriseID?: string | null,
        phoneNumber?: string | null,
        designation?: string | null,
        optin?: boolean | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetInvitationByIDQueryVariables = {
  id?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelInvitationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetInvitationByIDQuery = {
  getInvitationByID?:  {
    __typename: "ModelInvitationConnection",
    items?:  Array< {
      __typename: "Invitation",
      id: string,
      email: string,
      status: InvitationStatus,
      inviterID: string,
      inviterEnterpriseID: string,
      inviteeID?: string | null,
      roles?: Array< Role | null > | null,
      groupID?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      inviter?:  {
        __typename: "User",
        id: string,
        sub?: string | null,
        name?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email: string,
        enabled?: boolean | null,
        status: Status,
        roles: Array< Role >,
        lastActive?: string | null,
        deletedAt?: string | null,
        enterpriseID?: string | null,
        phoneNumber?: string | null,
        designation?: string | null,
        optin?: boolean | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListInvitationsByEmailQueryVariables = {
  email?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelInvitationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListInvitationsByEmailQuery = {
  listInvitationsByEmail?:  {
    __typename: "ModelInvitationConnection",
    items?:  Array< {
      __typename: "Invitation",
      id: string,
      email: string,
      status: InvitationStatus,
      inviterID: string,
      inviterEnterpriseID: string,
      inviteeID?: string | null,
      roles?: Array< Role | null > | null,
      groupID?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      inviter?:  {
        __typename: "User",
        id: string,
        sub?: string | null,
        name?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email: string,
        enabled?: boolean | null,
        status: Status,
        roles: Array< Role >,
        lastActive?: string | null,
        deletedAt?: string | null,
        enterpriseID?: string | null,
        phoneNumber?: string | null,
        designation?: string | null,
        optin?: boolean | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncInvitationsQueryVariables = {
  filter?: ModelInvitationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncInvitationsQuery = {
  syncInvitations?:  {
    __typename: "ModelInvitationConnection",
    items?:  Array< {
      __typename: "Invitation",
      id: string,
      email: string,
      status: InvitationStatus,
      inviterID: string,
      inviterEnterpriseID: string,
      inviteeID?: string | null,
      roles?: Array< Role | null > | null,
      groupID?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      inviter?:  {
        __typename: "User",
        id: string,
        sub?: string | null,
        name?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email: string,
        enabled?: boolean | null,
        status: Status,
        roles: Array< Role >,
        lastActive?: string | null,
        deletedAt?: string | null,
        enterpriseID?: string | null,
        phoneNumber?: string | null,
        designation?: string | null,
        optin?: boolean | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type OnCreateUserSubscriptionVariables = {
  sub?: string | null,
};

export type OnCreateUserSubscription = {
  onCreateUser?:  {
    __typename: "User",
    id: string,
    sub?: string | null,
    name?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    email: string,
    enabled?: boolean | null,
    status: Status,
    roles: Array< Role >,
    lastActive?: string | null,
    deletedAt?: string | null,
    enterpriseID?: string | null,
    phoneNumber?: string | null,
    designation?: string | null,
    optin?: boolean | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    groupMemberships?:  {
      __typename: "ModelGroupMemberConnection",
      items?:  Array< {
        __typename: "GroupMember",
        id: string,
        memberID: string,
        groupID: string,
        isManager?: boolean | null,
        isLearner?: boolean | null,
        userSub?: string | null,
        deletedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type OnUpdateUserSubscriptionVariables = {
  sub?: string | null,
};

export type OnUpdateUserSubscription = {
  onUpdateUser?:  {
    __typename: "User",
    id: string,
    sub?: string | null,
    name?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    email: string,
    enabled?: boolean | null,
    status: Status,
    roles: Array< Role >,
    lastActive?: string | null,
    deletedAt?: string | null,
    enterpriseID?: string | null,
    phoneNumber?: string | null,
    designation?: string | null,
    optin?: boolean | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    groupMemberships?:  {
      __typename: "ModelGroupMemberConnection",
      items?:  Array< {
        __typename: "GroupMember",
        id: string,
        memberID: string,
        groupID: string,
        isManager?: boolean | null,
        isLearner?: boolean | null,
        userSub?: string | null,
        deletedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type OnDeleteUserSubscriptionVariables = {
  sub?: string | null,
};

export type OnDeleteUserSubscription = {
  onDeleteUser?:  {
    __typename: "User",
    id: string,
    sub?: string | null,
    name?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    email: string,
    enabled?: boolean | null,
    status: Status,
    roles: Array< Role >,
    lastActive?: string | null,
    deletedAt?: string | null,
    enterpriseID?: string | null,
    phoneNumber?: string | null,
    designation?: string | null,
    optin?: boolean | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    groupMemberships?:  {
      __typename: "ModelGroupMemberConnection",
      items?:  Array< {
        __typename: "GroupMember",
        id: string,
        memberID: string,
        groupID: string,
        isManager?: boolean | null,
        isLearner?: boolean | null,
        userSub?: string | null,
        deletedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type OnCreateGroupSubscription = {
  onCreateGroup?:  {
    __typename: "Group",
    id: string,
    name: string,
    maxMembers?: number | null,
    deletedAt?: string | null,
    enterpriseID?: string | null,
    membersCount?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    members?:  {
      __typename: "ModelGroupMemberConnection",
      items?:  Array< {
        __typename: "GroupMember",
        id: string,
        memberID: string,
        groupID: string,
        isManager?: boolean | null,
        isLearner?: boolean | null,
        userSub?: string | null,
        deletedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type OnUpdateGroupSubscription = {
  onUpdateGroup?:  {
    __typename: "Group",
    id: string,
    name: string,
    maxMembers?: number | null,
    deletedAt?: string | null,
    enterpriseID?: string | null,
    membersCount?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    members?:  {
      __typename: "ModelGroupMemberConnection",
      items?:  Array< {
        __typename: "GroupMember",
        id: string,
        memberID: string,
        groupID: string,
        isManager?: boolean | null,
        isLearner?: boolean | null,
        userSub?: string | null,
        deletedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type OnDeleteGroupSubscription = {
  onDeleteGroup?:  {
    __typename: "Group",
    id: string,
    name: string,
    maxMembers?: number | null,
    deletedAt?: string | null,
    enterpriseID?: string | null,
    membersCount?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    members?:  {
      __typename: "ModelGroupMemberConnection",
      items?:  Array< {
        __typename: "GroupMember",
        id: string,
        memberID: string,
        groupID: string,
        isManager?: boolean | null,
        isLearner?: boolean | null,
        userSub?: string | null,
        deletedAt?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type OnCreateGroupMemberSubscriptionVariables = {
  userSub?: string | null,
};

export type OnCreateGroupMemberSubscription = {
  onCreateGroupMember?:  {
    __typename: "GroupMember",
    id: string,
    memberID: string,
    groupID: string,
    isManager?: boolean | null,
    isLearner?: boolean | null,
    userSub?: string | null,
    deletedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    member:  {
      __typename: "User",
      id: string,
      sub?: string | null,
      name?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      enabled?: boolean | null,
      status: Status,
      roles: Array< Role >,
      lastActive?: string | null,
      deletedAt?: string | null,
      enterpriseID?: string | null,
      phoneNumber?: string | null,
      designation?: string | null,
      optin?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      groupMemberships?:  {
        __typename: "ModelGroupMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    },
    group:  {
      __typename: "Group",
      id: string,
      name: string,
      maxMembers?: number | null,
      deletedAt?: string | null,
      enterpriseID?: string | null,
      membersCount?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      members?:  {
        __typename: "ModelGroupMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    },
  } | null,
};

export type OnUpdateGroupMemberSubscriptionVariables = {
  userSub?: string | null,
};

export type OnUpdateGroupMemberSubscription = {
  onUpdateGroupMember?:  {
    __typename: "GroupMember",
    id: string,
    memberID: string,
    groupID: string,
    isManager?: boolean | null,
    isLearner?: boolean | null,
    userSub?: string | null,
    deletedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    member:  {
      __typename: "User",
      id: string,
      sub?: string | null,
      name?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      enabled?: boolean | null,
      status: Status,
      roles: Array< Role >,
      lastActive?: string | null,
      deletedAt?: string | null,
      enterpriseID?: string | null,
      phoneNumber?: string | null,
      designation?: string | null,
      optin?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      groupMemberships?:  {
        __typename: "ModelGroupMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    },
    group:  {
      __typename: "Group",
      id: string,
      name: string,
      maxMembers?: number | null,
      deletedAt?: string | null,
      enterpriseID?: string | null,
      membersCount?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      members?:  {
        __typename: "ModelGroupMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    },
  } | null,
};

export type OnDeleteGroupMemberSubscriptionVariables = {
  userSub?: string | null,
};

export type OnDeleteGroupMemberSubscription = {
  onDeleteGroupMember?:  {
    __typename: "GroupMember",
    id: string,
    memberID: string,
    groupID: string,
    isManager?: boolean | null,
    isLearner?: boolean | null,
    userSub?: string | null,
    deletedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    member:  {
      __typename: "User",
      id: string,
      sub?: string | null,
      name?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      enabled?: boolean | null,
      status: Status,
      roles: Array< Role >,
      lastActive?: string | null,
      deletedAt?: string | null,
      enterpriseID?: string | null,
      phoneNumber?: string | null,
      designation?: string | null,
      optin?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      groupMemberships?:  {
        __typename: "ModelGroupMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    },
    group:  {
      __typename: "Group",
      id: string,
      name: string,
      maxMembers?: number | null,
      deletedAt?: string | null,
      enterpriseID?: string | null,
      membersCount?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      members?:  {
        __typename: "ModelGroupMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    },
  } | null,
};

export type OnCreateEnterpriseProfileSubscription = {
  onCreateEnterpriseProfile?:  {
    __typename: "EnterpriseProfile",
    id: string,
    name: string,
    licenseCount: number,
    membership?:  {
      __typename: "EnterpriseMembership",
      name: string,
      status: EnterpriseMembershipStatus,
      createdAt: string,
      updatedAt: string,
    } | null,
    subscription?:  {
      __typename: "EnterpriseSubscription",
      id: string,
      provider: SubscriptionProvider,
      providerSubID: string,
      providerCustomerID: string,
      status: EnterpriseSubscriptionStatus,
      entitlements:  Array< {
        __typename: "SubscriptionEntitlement",
        featureSlug: FeatureSlug,
        featureType: FeatureType,
        featureUnit?: string | null,
        value?: string | null,
      } | null >,
      currencyCode?: string | null,
      trialStart?: string | null,
      trialEnd?: string | null,
      currentTermStart?: string | null,
      currentTermEnd?: string | null,
      nextBillingAt?: string | null,
      cancelledAt?: string | null,
      items:  Array< {
        __typename: "SubscriptionItem",
        itemPriceId: string,
        itemType: SubscriptionItemType,
        quantity?: number | null,
        unitPrice?: string | null,
        amount?: string | null,
        freeQuantity?: number | null,
        name?: string | null,
      } | null >,
      billingPeriod?: number | null,
      billingPeriodUnit?: BillingPeriodUnit | null,
      resourceVersion?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    courseContentVersion?: number | null,
    deletedAt?: string | null,
    numberOfEmployees?: string | null,
    location?: string | null,
    hiddenCourses?: Array< string > | null,
    scormCloudAppID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateEnterpriseProfileSubscription = {
  onUpdateEnterpriseProfile?:  {
    __typename: "EnterpriseProfile",
    id: string,
    name: string,
    licenseCount: number,
    membership?:  {
      __typename: "EnterpriseMembership",
      name: string,
      status: EnterpriseMembershipStatus,
      createdAt: string,
      updatedAt: string,
    } | null,
    subscription?:  {
      __typename: "EnterpriseSubscription",
      id: string,
      provider: SubscriptionProvider,
      providerSubID: string,
      providerCustomerID: string,
      status: EnterpriseSubscriptionStatus,
      entitlements:  Array< {
        __typename: "SubscriptionEntitlement",
        featureSlug: FeatureSlug,
        featureType: FeatureType,
        featureUnit?: string | null,
        value?: string | null,
      } | null >,
      currencyCode?: string | null,
      trialStart?: string | null,
      trialEnd?: string | null,
      currentTermStart?: string | null,
      currentTermEnd?: string | null,
      nextBillingAt?: string | null,
      cancelledAt?: string | null,
      items:  Array< {
        __typename: "SubscriptionItem",
        itemPriceId: string,
        itemType: SubscriptionItemType,
        quantity?: number | null,
        unitPrice?: string | null,
        amount?: string | null,
        freeQuantity?: number | null,
        name?: string | null,
      } | null >,
      billingPeriod?: number | null,
      billingPeriodUnit?: BillingPeriodUnit | null,
      resourceVersion?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    courseContentVersion?: number | null,
    deletedAt?: string | null,
    numberOfEmployees?: string | null,
    location?: string | null,
    hiddenCourses?: Array< string > | null,
    scormCloudAppID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteEnterpriseProfileSubscription = {
  onDeleteEnterpriseProfile?:  {
    __typename: "EnterpriseProfile",
    id: string,
    name: string,
    licenseCount: number,
    membership?:  {
      __typename: "EnterpriseMembership",
      name: string,
      status: EnterpriseMembershipStatus,
      createdAt: string,
      updatedAt: string,
    } | null,
    subscription?:  {
      __typename: "EnterpriseSubscription",
      id: string,
      provider: SubscriptionProvider,
      providerSubID: string,
      providerCustomerID: string,
      status: EnterpriseSubscriptionStatus,
      entitlements:  Array< {
        __typename: "SubscriptionEntitlement",
        featureSlug: FeatureSlug,
        featureType: FeatureType,
        featureUnit?: string | null,
        value?: string | null,
      } | null >,
      currencyCode?: string | null,
      trialStart?: string | null,
      trialEnd?: string | null,
      currentTermStart?: string | null,
      currentTermEnd?: string | null,
      nextBillingAt?: string | null,
      cancelledAt?: string | null,
      items:  Array< {
        __typename: "SubscriptionItem",
        itemPriceId: string,
        itemType: SubscriptionItemType,
        quantity?: number | null,
        unitPrice?: string | null,
        amount?: string | null,
        freeQuantity?: number | null,
        name?: string | null,
      } | null >,
      billingPeriod?: number | null,
      billingPeriodUnit?: BillingPeriodUnit | null,
      resourceVersion?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    courseContentVersion?: number | null,
    deletedAt?: string | null,
    numberOfEmployees?: string | null,
    location?: string | null,
    hiddenCourses?: Array< string > | null,
    scormCloudAppID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateSourceSubscription = {
  onCreateSource?:  {
    __typename: "Source",
    id: string,
    name: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateSourceSubscription = {
  onUpdateSource?:  {
    __typename: "Source",
    id: string,
    name: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteSourceSubscription = {
  onDeleteSource?:  {
    __typename: "Source",
    id: string,
    name: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateReportSubscription = {
  onCreateReport?:  {
    __typename: "Report",
    id: string,
    sourceReferenceID: string,
    sourceID: string,
    locale: string,
    name: string,
    heading?: string | null,
    subHeading?: string | null,
    startCTA?: string | null,
    explainer?: string | null,
    assessment:  {
      __typename: "AssignmentTask",
      source: AssignmentSource,
      title: string,
      formId?: string | null,
    },
    skills:  Array< {
      __typename: "Skill",
      id: string,
      label: string,
      skillReport?:  {
        __typename: "Link",
        title: string,
        href: string,
      } | null,
      iconLink?:  {
        __typename: "Link",
        title: string,
        href: string,
      } | null,
      selectedIconLink?:  {
        __typename: "Link",
        title: string,
        href: string,
      } | null,
      subSkills:  Array< {
        __typename: "SubSkill",
        id: string,
        label: string,
        description?: string | null,
      } >,
    } >,
    deletedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    source?:  {
      __typename: "Source",
      id: string,
      name: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnUpdateReportSubscription = {
  onUpdateReport?:  {
    __typename: "Report",
    id: string,
    sourceReferenceID: string,
    sourceID: string,
    locale: string,
    name: string,
    heading?: string | null,
    subHeading?: string | null,
    startCTA?: string | null,
    explainer?: string | null,
    assessment:  {
      __typename: "AssignmentTask",
      source: AssignmentSource,
      title: string,
      formId?: string | null,
    },
    skills:  Array< {
      __typename: "Skill",
      id: string,
      label: string,
      skillReport?:  {
        __typename: "Link",
        title: string,
        href: string,
      } | null,
      iconLink?:  {
        __typename: "Link",
        title: string,
        href: string,
      } | null,
      selectedIconLink?:  {
        __typename: "Link",
        title: string,
        href: string,
      } | null,
      subSkills:  Array< {
        __typename: "SubSkill",
        id: string,
        label: string,
        description?: string | null,
      } >,
    } >,
    deletedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    source?:  {
      __typename: "Source",
      id: string,
      name: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnDeleteReportSubscription = {
  onDeleteReport?:  {
    __typename: "Report",
    id: string,
    sourceReferenceID: string,
    sourceID: string,
    locale: string,
    name: string,
    heading?: string | null,
    subHeading?: string | null,
    startCTA?: string | null,
    explainer?: string | null,
    assessment:  {
      __typename: "AssignmentTask",
      source: AssignmentSource,
      title: string,
      formId?: string | null,
    },
    skills:  Array< {
      __typename: "Skill",
      id: string,
      label: string,
      skillReport?:  {
        __typename: "Link",
        title: string,
        href: string,
      } | null,
      iconLink?:  {
        __typename: "Link",
        title: string,
        href: string,
      } | null,
      selectedIconLink?:  {
        __typename: "Link",
        title: string,
        href: string,
      } | null,
      subSkills:  Array< {
        __typename: "SubSkill",
        id: string,
        label: string,
        description?: string | null,
      } >,
    } >,
    deletedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    source?:  {
      __typename: "Source",
      id: string,
      name: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnCreateReportResultSubscriptionVariables = {
  userSub?: string | null,
};

export type OnCreateReportResultSubscription = {
  onCreateReportResult?:  {
    __typename: "ReportResult",
    id: string,
    reportID: string,
    userID: string,
    userSub: string,
    formID: string,
    responseID: string,
    skillScores:  Array< {
      __typename: "SkillScore",
      skillID: string,
      score: number,
      maxScore: number,
      subSkillScores:  Array< {
        __typename: "SubSkillScore",
        subSkillID: string,
        score: number,
        maxScore: number,
        scoreKey: string,
      } >,
    } >,
    deletedAt?: string | null,
    enterpriseID: string,
    archived?: boolean | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    user?:  {
      __typename: "User",
      id: string,
      sub?: string | null,
      name?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      enabled?: boolean | null,
      status: Status,
      roles: Array< Role >,
      lastActive?: string | null,
      deletedAt?: string | null,
      enterpriseID?: string | null,
      phoneNumber?: string | null,
      designation?: string | null,
      optin?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      groupMemberships?:  {
        __typename: "ModelGroupMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
    report?:  {
      __typename: "Report",
      id: string,
      sourceReferenceID: string,
      sourceID: string,
      locale: string,
      name: string,
      heading?: string | null,
      subHeading?: string | null,
      startCTA?: string | null,
      explainer?: string | null,
      assessment:  {
        __typename: "AssignmentTask",
        source: AssignmentSource,
        title: string,
        formId?: string | null,
      },
      skills:  Array< {
        __typename: "Skill",
        id: string,
        label: string,
      } >,
      deletedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      source?:  {
        __typename: "Source",
        id: string,
        name: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateReportResultSubscriptionVariables = {
  userSub?: string | null,
};

export type OnUpdateReportResultSubscription = {
  onUpdateReportResult?:  {
    __typename: "ReportResult",
    id: string,
    reportID: string,
    userID: string,
    userSub: string,
    formID: string,
    responseID: string,
    skillScores:  Array< {
      __typename: "SkillScore",
      skillID: string,
      score: number,
      maxScore: number,
      subSkillScores:  Array< {
        __typename: "SubSkillScore",
        subSkillID: string,
        score: number,
        maxScore: number,
        scoreKey: string,
      } >,
    } >,
    deletedAt?: string | null,
    enterpriseID: string,
    archived?: boolean | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    user?:  {
      __typename: "User",
      id: string,
      sub?: string | null,
      name?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      enabled?: boolean | null,
      status: Status,
      roles: Array< Role >,
      lastActive?: string | null,
      deletedAt?: string | null,
      enterpriseID?: string | null,
      phoneNumber?: string | null,
      designation?: string | null,
      optin?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      groupMemberships?:  {
        __typename: "ModelGroupMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
    report?:  {
      __typename: "Report",
      id: string,
      sourceReferenceID: string,
      sourceID: string,
      locale: string,
      name: string,
      heading?: string | null,
      subHeading?: string | null,
      startCTA?: string | null,
      explainer?: string | null,
      assessment:  {
        __typename: "AssignmentTask",
        source: AssignmentSource,
        title: string,
        formId?: string | null,
      },
      skills:  Array< {
        __typename: "Skill",
        id: string,
        label: string,
      } >,
      deletedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      source?:  {
        __typename: "Source",
        id: string,
        name: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteReportResultSubscriptionVariables = {
  userSub?: string | null,
};

export type OnDeleteReportResultSubscription = {
  onDeleteReportResult?:  {
    __typename: "ReportResult",
    id: string,
    reportID: string,
    userID: string,
    userSub: string,
    formID: string,
    responseID: string,
    skillScores:  Array< {
      __typename: "SkillScore",
      skillID: string,
      score: number,
      maxScore: number,
      subSkillScores:  Array< {
        __typename: "SubSkillScore",
        subSkillID: string,
        score: number,
        maxScore: number,
        scoreKey: string,
      } >,
    } >,
    deletedAt?: string | null,
    enterpriseID: string,
    archived?: boolean | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    user?:  {
      __typename: "User",
      id: string,
      sub?: string | null,
      name?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      enabled?: boolean | null,
      status: Status,
      roles: Array< Role >,
      lastActive?: string | null,
      deletedAt?: string | null,
      enterpriseID?: string | null,
      phoneNumber?: string | null,
      designation?: string | null,
      optin?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      groupMemberships?:  {
        __typename: "ModelGroupMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
    report?:  {
      __typename: "Report",
      id: string,
      sourceReferenceID: string,
      sourceID: string,
      locale: string,
      name: string,
      heading?: string | null,
      subHeading?: string | null,
      startCTA?: string | null,
      explainer?: string | null,
      assessment:  {
        __typename: "AssignmentTask",
        source: AssignmentSource,
        title: string,
        formId?: string | null,
      },
      skills:  Array< {
        __typename: "Skill",
        id: string,
        label: string,
      } >,
      deletedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      source?:  {
        __typename: "Source",
        id: string,
        name: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
  } | null,
};

export type OnCreateReportSubmissionSubscriptionVariables = {
  userSub?: string | null,
};

export type OnCreateReportSubmissionSubscription = {
  onCreateReportSubmission?:  {
    __typename: "ReportSubmission",
    id: string,
    reportID: string,
    userID: string,
    userSub: string,
    status: ReportSubmissionStatus,
    deletedAt?: string | null,
    enterpriseID: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateReportSubmissionSubscriptionVariables = {
  userSub?: string | null,
};

export type OnUpdateReportSubmissionSubscription = {
  onUpdateReportSubmission?:  {
    __typename: "ReportSubmission",
    id: string,
    reportID: string,
    userID: string,
    userSub: string,
    status: ReportSubmissionStatus,
    deletedAt?: string | null,
    enterpriseID: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteReportSubmissionSubscriptionVariables = {
  userSub?: string | null,
};

export type OnDeleteReportSubmissionSubscription = {
  onDeleteReportSubmission?:  {
    __typename: "ReportSubmission",
    id: string,
    reportID: string,
    userID: string,
    userSub: string,
    status: ReportSubmissionStatus,
    deletedAt?: string | null,
    enterpriseID: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateTypeformResponseSubscription = {
  onCreateTypeformResponse?:  {
    __typename: "TypeformResponse",
    id: string,
    formID: string,
    userID?: string | null,
    assessmentType?: string | null,
    payload: string,
    deletedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateTypeformResponseSubscription = {
  onUpdateTypeformResponse?:  {
    __typename: "TypeformResponse",
    id: string,
    formID: string,
    userID?: string | null,
    assessmentType?: string | null,
    payload: string,
    deletedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteTypeformResponseSubscription = {
  onDeleteTypeformResponse?:  {
    __typename: "TypeformResponse",
    id: string,
    formID: string,
    userID?: string | null,
    assessmentType?: string | null,
    payload: string,
    deletedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateCourseRegistrationSubscriptionVariables = {
  userSub?: string | null,
};

export type OnCreateCourseRegistrationSubscription = {
  onCreateCourseRegistration?:  {
    __typename: "CourseRegistration",
    id: string,
    courseID: string,
    userID: string,
    userSub?: string | null,
    status: CourseRegistrationStatus,
    deadline?: string | null,
    deletedAt?: string | null,
    enterpriseID?: string | null,
    assignedByUserID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateCourseRegistrationSubscriptionVariables = {
  userSub?: string | null,
};

export type OnUpdateCourseRegistrationSubscription = {
  onUpdateCourseRegistration?:  {
    __typename: "CourseRegistration",
    id: string,
    courseID: string,
    userID: string,
    userSub?: string | null,
    status: CourseRegistrationStatus,
    deadline?: string | null,
    deletedAt?: string | null,
    enterpriseID?: string | null,
    assignedByUserID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteCourseRegistrationSubscriptionVariables = {
  userSub?: string | null,
};

export type OnDeleteCourseRegistrationSubscription = {
  onDeleteCourseRegistration?:  {
    __typename: "CourseRegistration",
    id: string,
    courseID: string,
    userID: string,
    userSub?: string | null,
    status: CourseRegistrationStatus,
    deadline?: string | null,
    deletedAt?: string | null,
    enterpriseID?: string | null,
    assignedByUserID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateCourseRegistrationActivitySubscriptionVariables = {
  userSub?: string | null,
};

export type OnCreateCourseRegistrationActivitySubscription = {
  onCreateCourseRegistrationActivity?:  {
    __typename: "CourseRegistrationActivity",
    id: string,
    courseRegistrationID: string,
    userID: string,
    userSub?: string | null,
    taskID: string,
    taskType: TaskType,
    courseID: string,
    status: CourseRegistrationActivityStatus,
    deletedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    user?:  {
      __typename: "User",
      id: string,
      sub?: string | null,
      name?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      enabled?: boolean | null,
      status: Status,
      roles: Array< Role >,
      lastActive?: string | null,
      deletedAt?: string | null,
      enterpriseID?: string | null,
      phoneNumber?: string | null,
      designation?: string | null,
      optin?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      groupMemberships?:  {
        __typename: "ModelGroupMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
    courseRegistration?:  {
      __typename: "CourseRegistration",
      id: string,
      courseID: string,
      userID: string,
      userSub?: string | null,
      status: CourseRegistrationStatus,
      deadline?: string | null,
      deletedAt?: string | null,
      enterpriseID?: string | null,
      assignedByUserID?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnUpdateCourseRegistrationActivitySubscriptionVariables = {
  userSub?: string | null,
};

export type OnUpdateCourseRegistrationActivitySubscription = {
  onUpdateCourseRegistrationActivity?:  {
    __typename: "CourseRegistrationActivity",
    id: string,
    courseRegistrationID: string,
    userID: string,
    userSub?: string | null,
    taskID: string,
    taskType: TaskType,
    courseID: string,
    status: CourseRegistrationActivityStatus,
    deletedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    user?:  {
      __typename: "User",
      id: string,
      sub?: string | null,
      name?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      enabled?: boolean | null,
      status: Status,
      roles: Array< Role >,
      lastActive?: string | null,
      deletedAt?: string | null,
      enterpriseID?: string | null,
      phoneNumber?: string | null,
      designation?: string | null,
      optin?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      groupMemberships?:  {
        __typename: "ModelGroupMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
    courseRegistration?:  {
      __typename: "CourseRegistration",
      id: string,
      courseID: string,
      userID: string,
      userSub?: string | null,
      status: CourseRegistrationStatus,
      deadline?: string | null,
      deletedAt?: string | null,
      enterpriseID?: string | null,
      assignedByUserID?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnDeleteCourseRegistrationActivitySubscriptionVariables = {
  userSub?: string | null,
};

export type OnDeleteCourseRegistrationActivitySubscription = {
  onDeleteCourseRegistrationActivity?:  {
    __typename: "CourseRegistrationActivity",
    id: string,
    courseRegistrationID: string,
    userID: string,
    userSub?: string | null,
    taskID: string,
    taskType: TaskType,
    courseID: string,
    status: CourseRegistrationActivityStatus,
    deletedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    user?:  {
      __typename: "User",
      id: string,
      sub?: string | null,
      name?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      enabled?: boolean | null,
      status: Status,
      roles: Array< Role >,
      lastActive?: string | null,
      deletedAt?: string | null,
      enterpriseID?: string | null,
      phoneNumber?: string | null,
      designation?: string | null,
      optin?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      groupMemberships?:  {
        __typename: "ModelGroupMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
    courseRegistration?:  {
      __typename: "CourseRegistration",
      id: string,
      courseID: string,
      userID: string,
      userSub?: string | null,
      status: CourseRegistrationStatus,
      deadline?: string | null,
      deletedAt?: string | null,
      enterpriseID?: string | null,
      assignedByUserID?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnCreateAssignmentResponseSubscriptionVariables = {
  userSub?: string | null,
};

export type OnCreateAssignmentResponseSubscription = {
  onCreateAssignmentResponse?:  {
    __typename: "AssignmentResponse",
    id: string,
    courseRegistrationID: string,
    userID: string,
    userSub: string,
    taskID: string,
    source: AssignmentSource,
    formID: string,
    responseID: string,
    score: number,
    totalScore: number,
    deletedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateAssignmentResponseSubscriptionVariables = {
  userSub?: string | null,
};

export type OnUpdateAssignmentResponseSubscription = {
  onUpdateAssignmentResponse?:  {
    __typename: "AssignmentResponse",
    id: string,
    courseRegistrationID: string,
    userID: string,
    userSub: string,
    taskID: string,
    source: AssignmentSource,
    formID: string,
    responseID: string,
    score: number,
    totalScore: number,
    deletedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteAssignmentResponseSubscriptionVariables = {
  userSub?: string | null,
};

export type OnDeleteAssignmentResponseSubscription = {
  onDeleteAssignmentResponse?:  {
    __typename: "AssignmentResponse",
    id: string,
    courseRegistrationID: string,
    userID: string,
    userSub: string,
    taskID: string,
    source: AssignmentSource,
    formID: string,
    responseID: string,
    score: number,
    totalScore: number,
    deletedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateNotificationSubscriptionVariables = {
  userSub?: string | null,
};

export type OnCreateNotificationSubscription = {
  onCreateNotification?:  {
    __typename: "Notification",
    id: string,
    userID: string,
    userSub: string,
    title: string,
    message?: string | null,
    read?: boolean | null,
    deletedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    user?:  {
      __typename: "User",
      id: string,
      sub?: string | null,
      name?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      enabled?: boolean | null,
      status: Status,
      roles: Array< Role >,
      lastActive?: string | null,
      deletedAt?: string | null,
      enterpriseID?: string | null,
      phoneNumber?: string | null,
      designation?: string | null,
      optin?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      groupMemberships?:  {
        __typename: "ModelGroupMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateNotificationSubscriptionVariables = {
  userSub?: string | null,
};

export type OnUpdateNotificationSubscription = {
  onUpdateNotification?:  {
    __typename: "Notification",
    id: string,
    userID: string,
    userSub: string,
    title: string,
    message?: string | null,
    read?: boolean | null,
    deletedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    user?:  {
      __typename: "User",
      id: string,
      sub?: string | null,
      name?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      enabled?: boolean | null,
      status: Status,
      roles: Array< Role >,
      lastActive?: string | null,
      deletedAt?: string | null,
      enterpriseID?: string | null,
      phoneNumber?: string | null,
      designation?: string | null,
      optin?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      groupMemberships?:  {
        __typename: "ModelGroupMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteNotificationSubscriptionVariables = {
  userSub?: string | null,
};

export type OnDeleteNotificationSubscription = {
  onDeleteNotification?:  {
    __typename: "Notification",
    id: string,
    userID: string,
    userSub: string,
    title: string,
    message?: string | null,
    read?: boolean | null,
    deletedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    user?:  {
      __typename: "User",
      id: string,
      sub?: string | null,
      name?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      enabled?: boolean | null,
      status: Status,
      roles: Array< Role >,
      lastActive?: string | null,
      deletedAt?: string | null,
      enterpriseID?: string | null,
      phoneNumber?: string | null,
      designation?: string | null,
      optin?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      groupMemberships?:  {
        __typename: "ModelGroupMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateInvitationSubscription = {
  onCreateInvitation?:  {
    __typename: "Invitation",
    id: string,
    email: string,
    status: InvitationStatus,
    inviterID: string,
    inviterEnterpriseID: string,
    inviteeID?: string | null,
    roles?: Array< Role | null > | null,
    groupID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    inviter?:  {
      __typename: "User",
      id: string,
      sub?: string | null,
      name?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      enabled?: boolean | null,
      status: Status,
      roles: Array< Role >,
      lastActive?: string | null,
      deletedAt?: string | null,
      enterpriseID?: string | null,
      phoneNumber?: string | null,
      designation?: string | null,
      optin?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      groupMemberships?:  {
        __typename: "ModelGroupMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateInvitationSubscription = {
  onUpdateInvitation?:  {
    __typename: "Invitation",
    id: string,
    email: string,
    status: InvitationStatus,
    inviterID: string,
    inviterEnterpriseID: string,
    inviteeID?: string | null,
    roles?: Array< Role | null > | null,
    groupID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    inviter?:  {
      __typename: "User",
      id: string,
      sub?: string | null,
      name?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      enabled?: boolean | null,
      status: Status,
      roles: Array< Role >,
      lastActive?: string | null,
      deletedAt?: string | null,
      enterpriseID?: string | null,
      phoneNumber?: string | null,
      designation?: string | null,
      optin?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      groupMemberships?:  {
        __typename: "ModelGroupMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteInvitationSubscription = {
  onDeleteInvitation?:  {
    __typename: "Invitation",
    id: string,
    email: string,
    status: InvitationStatus,
    inviterID: string,
    inviterEnterpriseID: string,
    inviteeID?: string | null,
    roles?: Array< Role | null > | null,
    groupID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    inviter?:  {
      __typename: "User",
      id: string,
      sub?: string | null,
      name?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      enabled?: boolean | null,
      status: Status,
      roles: Array< Role >,
      lastActive?: string | null,
      deletedAt?: string | null,
      enterpriseID?: string | null,
      phoneNumber?: string | null,
      designation?: string | null,
      optin?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      groupMemberships?:  {
        __typename: "ModelGroupMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
    } | null,
  } | null,
};
