import React, { useState, useEffect, useContext } from 'react';
import { navigate } from 'gatsby';
import {
  Modal, Select, Form, Tag,
} from 'antd';
import PropTypes from 'prop-types';
import {
  Role,
} from '../../../../models/index';
import {
  listGroups, listUsers, listGroupsForManager, listUsersForManager,
} from '../../../DataStore/Services';
import * as commonPopupStyles from '../../../../styles/admin/PopupCommon.module.scss';
import * as adduserStyles from '../../../../styles/admin/AddUser.module.scss';
import AdminContext from '../../../../context/Admin/AdminContext';
import { toaster } from '../../../../services/utils';

const AssignCoursePopUp = ({
  isModalVisible, onOk, onCancel, selectedCourse,
}) => {
  const { profileInfo } = useContext(AdminContext);
  const [selectedUsersLength, setSelectedUsersLength] = useState(0);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [groupList, setGroupList] = useState([]);
  const [emailList, setEmailList] = useState([]);
  const [form] = Form.useForm();

  /**
   * listing groups for which user is manager
   *
   * @param {*}
   * @param {*}
   */
  const listGroupsForManagerHandler = async () => {
    const sub = profileInfo?.sub;
    const id = profileInfo?.id;
    const groups = await listGroupsForManager(sub, id);
    if (groups) {
      if (groups.length === 0
        && !(profileInfo?.roles?.includes(Role.ADMIN)
        || profileInfo?.roles?.includes(Role.MANAGER)
        || profileInfo?.roles?.includes(Role.OWNER))) {
        navigate('/', { replace: true });
      } else {
        const groupOtions = groups?.map((group) => (
          { label: group.name, value: group.id }
        ));
        setGroupList(groupOtions);
      }
    }
  };

  /**
   *listing users when manager logs in
   *
   * @param {*}
   * @param {*}
   */
  const listUsersForManagerHandler = async () => {
    const sub = profileInfo?.sub;
    const id = profileInfo?.id;
    const responseList = await listUsersForManager(sub, id);
    if (responseList?.groups) {
      if (responseList?.groups?.length === 0
        && !(profileInfo?.roles?.includes(Role.ADMIN)
        || profileInfo?.roles?.includes(Role.MANAGER)
        || profileInfo?.roles?.includes(Role.OWNER))) {
        navigate('/', { replace: true });
      }
    }
    if (responseList?.userList) {
      const emails = responseList?.userList?.map((user) => (
        user.enabled && { label: user.email, value: user.sub }
      ));
      setEmailList(emails);
    }
  };
  const fetchGroups = async () => {
    const groups = await listGroups();
    if (groups?.length !== 0) {
      const groupOtions = groups?.map((group) => (
        { label: group.name, value: group.id }
      ));
      setGroupList(groupOtions);
    }
  };
  const listUsersHandler = async () => {
    const users = await listUsers();
    if (users) {
      const emails = users?.filter((user) => (user.enabled))
        .map((user) => ({ label: user.email, value: user.sub }));
      setEmailList(emails);
    }
  };
  const fetchSignedInUserDetails = async () => {
    if (profileInfo?.roles.includes(Role.ADMIN) || profileInfo?.roles.includes(Role.OWNER)) {
      fetchGroups();
      listUsersHandler();
    } else if (profileInfo?.roles.includes(Role.MANAGER)) {
      listGroupsForManagerHandler();
      listUsersForManagerHandler();
    } else {
      navigate('/learner/');
    }
  };

  useEffect(() => {
    if (profileInfo?.id) {
      fetchSignedInUserDetails();
    }
  }, [profileInfo]);
  /**
   * clear all tags
   *
   */
  const clearAll = () => {
    form.setFieldsValue({
      selectedUsers: [],
    });
    setSelectedUsersLength(0);
  };

  /**
   * close tag
   *
   * @param {*} value
   */
  const deselectTagEmail = (value) => {
    const selectedTagEmail = form.getFieldValue('selectedUsers');
    selectedTagEmail?.splice(selectedTagEmail.indexOf(value), 1);
    form.setFieldsValue({
      selectedUsers: [...selectedTagEmail],
    });
    setSelectedUsersLength(selectedTagEmail.length);
  };

  /**
   * Tag rendering based on email validation
   *
   * @param {*} { value }
   * @return {*}
   */
  const renderEmailTag = ({ label, value }) => {
    // email validation
    const validEmail = (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(label.trim()));
    if (!validEmail) {
      setInvalidEmail(true);
    } else {
      setInvalidEmail(false);
    }
    return (
      <Tag
        className={validEmail ? 'valid-email' : 'invalid-email'}
        closable
        onClose={() => deselectTagEmail(value)}
      >
        {label}
      </Tag>
    );
  };
  const onFieldsChange = (changedFields) => {
    if (changedFields[0]?.name.includes('selectedUsers')) {
      setSelectedUsersLength(changedFields[0].value.length);
    }
  };
  const submitForm = (values) => {
    onOk(values);
    form.setFieldsValue({ selectedGroups: [], selectedUsers: [] });
    setSelectedUsersLength(0);
  };
  return (
    <Modal
      visible={isModalVisible}
      onCancel={() => {
        form.setFieldsValue({
          selectedUsers: [],
        });
        setSelectedUsersLength(0);
        onCancel();
      }}
      width="85%"
      className="wrapper assign-course-wrapper"
      footer={null}
    >
      <Form
        form={form}
        onFinish={submitForm}
        onFieldsChange={onFieldsChange}
      >
        <div className={adduserStyles.addUserWrapper}>
          <div className={commonPopupStyles.titleWrapper}>
            <p>Assign Course</p>
            <button
              type="button"
              className={commonPopupStyles.confirmButton}
              onClick={() => {
                if (invalidEmail) {
                  toaster('Invalid emails present', 'error');
                  return null;
                }
                return form.submit(form.getFieldsValue());
              }}
            >
              Send Invite
            </button>
          </div>
          {
          selectedCourse && Object.keys(selectedCourse)?.length !== 0
        && <p className={adduserStyles.groupName}>{`Course : ${selectedCourse?.courseName}`}</p>
        }
          <div className={`${adduserStyles.addEmail} add-email`}>
            <p className={adduserStyles.labelWrapper}>
              <p>Group Name</p>
            </p>
            <Form.Item
              name="selectedGroups"
            >
              <Select
                mode="tags"
                style={{ width: '100%' }}
                placeholder="Select group"
                options={groupList}
                optionFilterProp="label"
                filterOption={
                (input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
                optionLabelProp="label"
                filterSort={
                (optionA, optionB) => optionA.label.toLowerCase()
                  .localeCompare(optionB.label.toLowerCase())
              }
              />
            </Form.Item>
            <p className={adduserStyles.labelWrapper}>
              <p>{`Search for users (${selectedUsersLength})`}</p>
              <button type="button" onClick={clearAll}>CLEAR</button>
            </p>
            <Form.Item
              name="selectedUsers"
            >
              <Select
                mode="tags"
                style={{ width: '100%' }}
                placeholder="Please enter email id"
                tagRender={renderEmailTag}
                options={emailList}
                optionFilterProp="label"
                filterOption={
                (input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
                optionLabelProp="label"
                filterSort={
                (optionA, optionB) => optionA.label.toLowerCase()
                  .localeCompare(optionB.label.toLowerCase())
              }
              />
            </Form.Item>
          </div>
        </div>
      </Form>
    </Modal>
  );
};
AssignCoursePopUp.propTypes = {
  isModalVisible: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  selectedCourse: PropTypes.objectOf(PropTypes.any),
};
AssignCoursePopUp.defaultProps = {
  selectedCourse: {},
};

export default AssignCoursePopUp;
