// extracted by mini-css-extract-plugin
export var accountPageWrapper = "Account-module--accountPageWrapper--opdCe";
export var accountWrapper = "Account-module--accountWrapper--2QPAW";
export var titleHeader = "Account-module--titleHeader--2YtGt";
export var title = "Account-module--title--2dkuQ";
export var infoIcon = "Account-module--infoIcon--3Ro4L";
export var titleTabContainer = "Account-module--titleTabContainer--3-8pJ";
export var tabContainer = "Account-module--tabContainer--20BzX";
export var mobileTabSelect = "Account-module--mobileTabSelect--2INZ2";
export var selectDropdown = "Account-module--selectDropdown--OpvJ6";
export var tabText = "Account-module--tabText--2gyR-";
export var tabTextActive = "Account-module--tabTextActive--2-t-w";